import React, { useEffect, useRef } from 'react';
import themes from '~/themes';
import { TextInput, TextInputProps } from '../TextInput';
import { SearchInputPrefix } from './SearchInputPrefix';

export interface ISearchInputProps extends TextInputProps {
  placeholder?: string;
  value?: string;
  fullWidth?: boolean;
  error?: boolean;
  isSearching?: boolean;
  searchSuggestion?: string;
  onChange?: (v: any) => void;
  onKeyDown?: (v: any) => void;
  onClear?: () => void;
  showSeachIcon?: boolean;
}

export const SearchInput: React.FC<ISearchInputProps> = ({
  placeholder = 'Enter 3 or more characters',
  searchSuggestion = 'Enter 3 or more characters',
  value,
  fullWidth,
  error,
  isSearching = false,
  onClear,
  onKeyDown,
  showSeachIcon = true,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(document.createElement('input'));
  useEffect(() => {
    const changePlaceholder = () => {
      inputRef.current.placeholder = searchSuggestion;
      inputRef.current.classList.add('custom-placeholder');
    };
    const defaultPlaceholder = () => {
      inputRef.current.placeholder = placeholder;
      inputRef.current.classList.remove('custom-placeholder');
    };
    inputRef.current.addEventListener('focus', changePlaceholder);
    inputRef.current.addEventListener('blur', defaultPlaceholder);

    return () => {
      inputRef.current?.removeEventListener('focus', changePlaceholder);
      inputRef.current?.removeEventListener('blur', defaultPlaceholder);
    };
  }, [searchSuggestion]);
  const inputProps = rest.inputProps || {};
  inputProps['data-testid'] = `txt-${rest['data-testid'] || rest.id || ''}`;
  return (
    <TextInput
      id='search'
      value={value}
      sx={{
        '& .MuiOutlinedInput-input': {
          '&.custom-placeholder::placeholder': {
            color: themes.bg.gray600,
          },
        },
      }}
      {...rest}
      onKeyDown={onKeyDown}
      inputRef={inputRef}
      placeholder={placeholder}
      suffix={
        <SearchInputPrefix
          showSeachIcon={showSeachIcon}
          isSearching={!!value || isSearching}
          onClearClick={() => {
            if (value || isSearching) {
              onClear();
            }
          }}
        />
      }
    />
  );
};
