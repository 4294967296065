import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import { random, uniq } from 'lodash';
import { memo, useMemo } from 'react';
import { Icon } from '~/components/common/Icon';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { useKeyHold } from '~/pages/LiveTracking/hooks/useKeyHold';
import { useLiveMapContext } from '~/pages/LiveTracking/hooks/useLiveMapContext';
import { useOperatingSystem } from '~/pages/LiveTracking/hooks/useOperatingSystem';
import {
  StyledItemContainer,
  StyledSelectedItem,
} from '~/pages/LiveTracking/style';
import { Task, TaskStatus } from '~/pages/LiveTracking/types';
import { mappingTaskStatusData } from '~/pages/LiveTracking/utils';
import { formatDeliveryDateTimeTask } from '~/utils/formatter';
import themes from '~/themes';
import TaskCompleteImg from '~/assets/images/background/setup-task.png';
import { Virtuoso } from 'react-virtuoso';
import { FirstLoading } from '../../FirstLoading';

export const TaskStatusText = ({ status }: { status: TaskStatus }) => (
  <span
    style={{ color: mappingTaskStatusData[status]?.color, fontWeight: 500 }}
  >
    {mappingTaskStatusData[status]?.text}
  </span>
);

type TaskListProps = {
  data: Task[];
  isLoading?: boolean;
  isFirstLoading?: boolean;
  isFiltered?: boolean;
};

export const TaskList: React.FC<TaskListProps> = memo(
  (props: TaskListProps) => {
    const { data, isLoading = false, isFirstLoading, isFiltered } = props;

    const {
      taskId,
      multiTaskIds,
      isAssignMultiTask,
      setMultiTaskIds,
      handleOpenTaskDetail,
      handleCloseTaskDetail,
    } = useLiveMapContext();

    const cmdHeld = useKeyHold('Meta');
    const ctrlHeld = useKeyHold('Control');
    const { isMacOs, isWindow } = useOperatingSystem();

    const handleTaskSelect = (id: string) => {
      if ((cmdHeld && isMacOs) || (ctrlHeld && isWindow)) {
        let taskIds = [...multiTaskIds];

        if (taskId && !isAssignMultiTask) {
          taskIds = [...taskIds, taskId];
        }

        if (taskIds.includes(id)) {
          taskIds = taskIds.filter((tId) => tId !== id);
        } else {
          taskIds = [...taskIds, id];
        }
        setMultiTaskIds(uniq(taskIds));
      } else if (taskId === id) {
        handleCloseTaskDetail();
      } else {
        handleOpenTaskDetail(id);
      }
    };

    const taskListSkeletonLoading = useMemo(
      () =>
        Array(7)
          .fill(0)
          .map(() => (
            <Stack
              gap={1}
              p={1}
              borderRadius='10px'
              sx={{ background: 'white' }}
            >
              <Stack flexDirection='row' justifyContent='space-between'>
                <Skeleton animation={false} width={random(120, 220)} />
                <Skeleton animation={false} width={40} />
              </Stack>
              <Skeleton animation={false} width='40%' />
              <Skeleton animation={false} width='30%' />
            </Stack>
          )),
      [],
    );

    if (isFirstLoading) return <FirstLoading />;

    if (isLoading) {
      return <Stack spacing={1}>{taskListSkeletonLoading}</Stack>;
    }

    if (!data.length && isFiltered)
      return (
        <Typography textAlign='center' color={themes.color.violet900}>
          No results found
        </Typography>
      );

    if (!data.length)
      return (
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
          spacing={1}
        >
          <img src={TaskCompleteImg} width={400} alt='task-completed-bg-img' />
          <Typography color={themes.color.violet900}>
            All tasks are completed!
          </Typography>
        </Stack>
      );

    return (
      <Virtuoso
        style={{
          height: '100%',
        }}
        data={data}
        className='customized-scrollbar'
        itemContent={(_, task) => {
          const {
            id,
            status,
            pickup,
            delivery,
            name,
            executor,
            expected_delivery_before,
            expected_delivery_after,
            isDivider,
          } = task;
          if (isDivider)
            return (
              <Divider key='divider-task' sx={{ my: '20px !important' }} />
            );
          const isTaskSelected = taskId === id && !isAssignMultiTask;
          const isMultiTaskSelected = multiTaskIds.includes(id);
          return (
            <StyledItemContainer
              spacing={0.5}
              key={id}
              isSelected={isTaskSelected || isMultiTaskSelected}
              onClick={() => handleTaskSelect(id)}
            >
              <StyledSelectedItem
                className='selected-task'
                isPickup={!!pickup}
              />
              <Stack direction='row' alignItems='center'>
                <TaskStatusText status={status} />
                {executor && (
                  <>
                    <Icon
                      name='car-filter'
                      baseUrl='map'
                      color={themes.color.black}
                      size={16}
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                    <ToolTipText text={executor?.display_name} maxLength={20} />
                  </>
                )}

                <Typography color='#8C95BA' ml='auto'>
                  {name}
                </Typography>
              </Stack>
              {pickup && (
                <ToolTipText
                  text={`${pickup?.address_1}, ${pickup?.city}`}
                  maxLength={30}
                />
              )}
              <Stack direction='row' alignItems='center'>
                <Icon
                  name='dropoff-inprogress-filter'
                  baseUrl='map'
                  color={themes.color.black}
                  size={16}
                  style={{ marginRight: 5 }}
                />
                <ToolTipText
                  text={`${delivery?.address_1}, ${delivery?.city}`}
                  maxLength={20}
                />
                <Typography color='#8C95BA' ml='auto'>
                  {formatDeliveryDateTimeTask({
                    afterDate: expected_delivery_after,
                    beforeDate: expected_delivery_before,
                  })}
                </Typography>
              </Stack>
            </StyledItemContainer>
          );
        }}
      />
    );
  },
);
