import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { LiveMonitoring as LiveMonitoringComponent } from './LiveMonitoring';
import { LiveMonitoring as LiveMonitoringWithRefreshTaskComponent } from './LiveMonitoringWithRefreshTask';

const LiveMonitoring = () => {
  const isEnableRefreshTask = useFeatureIsOn('cf-1116-optimize-live-map');
  if (isEnableRefreshTask) return <LiveMonitoringWithRefreshTaskComponent />;
  return <LiveMonitoringComponent />;
};

export { LiveMonitoring };

// export * from './LiveMonitoring';
