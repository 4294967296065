import ReactDOMServer from 'react-dom/server';
import DropOffIcon from '~/assets/images/map/drop-off.svg';
import CloseIcon from '~/assets/images/icons/close.svg';
import StoreIcon from '~/assets/images/map/store-white.svg';
import NoAvatar from '~/assets/images/icons/no-avatar.svg';
import {
  genAvatarSrc,
  stringToAvatar,
} from '~/components/shared/AssignTask/DriverAvatar';

import UnassignedIcon from '~/assets/images/map/dropoff-not-assigned.svg';
import PendingIcon from '~/assets/images/map/dropoff-filter.svg';
import InprogressIcon from '~/assets/images/map/dropoff-inprogress-filter.svg';
import { getTaskIcon } from '../../utils';
import { DriverStatus, IDriverRoute, LocationInfo } from '../../types';

function determineStatus(locations: LocationInfo[]) {
  if (!locations) return locations?.[0]?.status;
  if (locations.some((location) => location.status === 'unassigned')) {
    return 'unassigned';
  }
  if (locations.some((location) => location.status === 'pending_pickup')) {
    return 'pending_pickup';
  }
  if (locations.some((location) => location.status === 'delivering')) {
    return 'delivering';
  }
  return '';
}

export const DropOffMarkerContent = ({
  clustersCount,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  locations,
}: MarkerContentParams) => {
  const status = determineStatus(locations);

  const icons = {
    unassigned: UnassignedIcon,
    pending_pickup: PendingIcon,
    delivering: InprogressIcon,
  };
  const container = document.createElement('div');
  container.style.display = 'flex';
  container.style.justifyContent = 'center';
  container.style.alignItems = 'center';
  if (!icons[status] || clustersCount) {
    container.style.color = 'white';
    container.style.fontFamily = 'DM Sans';
    container.style.width = '10px';
    container.style.height = '10px';
    container.style.padding = '9px';
    container.style.borderRadius = '50%';
    container.style.border = '1px solid white';
    container.style.background = '#160E4D';
  }
  if (clustersCount) {
    container.innerHTML = clustersCount?.toString();
  } else if (icons[status]) {
    container.style.width = '30px';
    container.style.height = '30px';
    container.innerHTML = ReactDOMServer.renderToString(
      <img
        alt='drop-off-icon'
        style={{ width: 24, height: 24 }}
        src={icons[status] || InprogressIcon}
      />,
    );
  } else
    container.innerHTML = ReactDOMServer.renderToString(
      <img alt='drop-off-icon' src={DropOffIcon} />,
    );

  return container;
};

type MarkerContentParams = {
  clustersCount?: number;
  locations?: LocationInfo[];
};

export const StoreMarkerContent = ({ clustersCount }: MarkerContentParams) => {
  const container = document.createElement('div');
  container.style.display = 'flex';
  container.style.justifyContent = 'center';
  container.style.alignItems = 'center';
  container.style.width = '10px';
  container.style.height = '10px';
  container.style.padding = '9px';
  container.style.background = '#F7982A';
  container.style.borderRadius = '50%';
  container.style.border = '1px solid white';
  container.style.color = 'white';
  container.style.fontFamily = 'DM Sans';

  if (clustersCount) {
    container.innerHTML = clustersCount?.toString();
  } else {
    const count = 0;
    if (count > 1) {
      container.style.display = 'flex';
      container.style.justifyContent = 'center';
      container.style.alignItems = 'center';
    }
    container.innerHTML = ReactDOMServer.renderToString(
      count > 1 ? (
        <>
          <span style={{ marginRight: 2, fontSize: 10 }}>
            {count?.toString()}
          </span>
          <img width={8} height={8} alt='store-icon' src={StoreIcon} />
        </>
      ) : (
        <img alt='store-icon' src={StoreIcon} />
      ),
    );
  }

  return container;
};

export const DriverMarkerContent = (data: {
  avatar?: string;
  driverStatus?: DriverStatus;
  name?: string;
}) => {
  const container = document.createElement('div');
  container.style.width = '60px';
  container.style.height = '60px';
  container.style.borderRadius = '50%';
  container.style.display = 'flex';
  container.style.justifyContent = 'center';
  container.style.alignItems = 'center';
  container.style.backgroundColor = 'rgba(0, 116, 201, 0.3)';
  if (data.driverStatus === DriverStatus.Offline) {
    container.style.backgroundColor = 'rgba(98, 98, 98, 0.30)';
  }
  if (data.driverStatus === DriverStatus.Online) {
    container.style.backgroundColor = 'rgba(0, 116, 201, 0.30)';
  }
  if (data.driverStatus === DriverStatus.Idle) {
    container.style.backgroundColor = 'rgba(247, 152, 42, 0.30)';
  }

  if (!data?.avatar) {
    container.innerHTML = ReactDOMServer.renderToString(
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#bdbdbd',
          color: 'white',
          borderRadius: '50%',
          border: '2px solid white',
          opacity: 1,
          width: 36,
          height: 36,
          fontSize: 20,
          fontWeight: 500,
          fontFamily: 'DM Sans',
        }}
      >
        {stringToAvatar(data.name)}
      </div>,
    );
  } else {
    container.innerHTML = ReactDOMServer.renderToString(
      <img
        style={{
          background: 'white',
          borderRadius: '50%',
          border: '2px solid white',
          opacity: 1,
        }}
        alt='driver-avatar'
        width={36}
        height={36}
        src={data?.avatar ? genAvatarSrc(data?.avatar) : NoAvatar}
      />,
    );
  }

  return container;
};

export const StoreInfoWindowContent = (location?: LocationInfo) => {
  const container = document.createElement('div');
  container.style.padding = '10px';
  container.style.borderRadius = '5px';
  container.style.background = 'white';
  container.style.textAlign = 'center';
  container.style.display = 'flex';
  container.style.flexDirection = 'column';
  container.style.fontFamily = 'DM Sans';
  container.style.fontSize = '14px';

  container.innerHTML = ReactDOMServer.renderToString(
    <>
      <span style={{ fontWeight: 500, marginBottom: 5 }}>{location.name}</span>
      <span>{`${location.address_1}, ${location.city}`}</span>
    </>,
  );
  return container;
};

export const DropOffInfoWindowContent = ({
  locations,
  closeBtnId,
}: {
  locations?: LocationInfo[];
  closeBtnId: string;
}) => {
  const container = document.createElement('div');
  container.style.borderRadius = '5px';
  container.style.background = 'white';
  container.style.textAlign = 'center';
  container.style.display = 'flex';
  container.style.flexDirection = 'column';
  container.style.overflow = 'hidden';
  container.style.fontFamily = 'DM Sans';

  container.innerHTML = ReactDOMServer.renderToString(
    <>
      <button
        type='button'
        id={closeBtnId}
        style={{ background: '#BFC3E0', border: 'none', cursor: 'pointer' }}
      >
        <img width={8} height={8} alt='close-icon' src={CloseIcon} />
      </button>
      <ul style={{ padding: 0, margin: 0, background: '#160E4D' }}>
        {locations.map((l) => (
          <li
            key={l.id}
            id={l.id}
            data-id={l.task_id}
            style={{
              cursor: 'pointer',
              flex: 1,
              borderBottom: '1px solid white',
              padding: '5px',
              color: 'white',
            }}
          >
            <img width={8} height={8} alt='drop-off-icon' src={DropOffIcon} />
            <span style={{ marginLeft: '5px', fontSize: 10 }}>
              {l.task_name}
            </span>
          </li>
        ))}
      </ul>
    </>,
  );
  return container;
};

export const DriverRouteInfoWindow = (
  routes: IDriverRoute[],
  opts: {
    isOnManageRoutes?: boolean;
    isExpanded?: boolean;
    latLngId?: string;
    isOnlyOneRoute?: boolean;
  },
) => {
  const { isOnManageRoutes, isExpanded, latLngId } = opts;
  const container = document.createElement('div');
  container.style.borderRadius = '5px';
  container.style.background = 'white';
  container.style.textAlign = 'center';
  container.style.display = 'flex';
  container.style.flexDirection = 'column';
  container.style.overflow = 'hidden';
  container.style.fontFamily = 'DM Sans';

  if (isOnManageRoutes) {
    container.style.overflow = 'hidden';
    container.style.padding = '10px';
    container.style.background = 'transparent';
    container.innerHTML = ReactDOMServer.renderToString(
      <>
        {routes.length > 5 && isExpanded && (
          <button
            id={`close-btn_${latLngId}`}
            data-id={latLngId}
            type='button'
            style={{
              background: '#BFC3E0',
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              border: 'none',
              cursor: 'pointer',
              zIndex: 2,
            }}
          >
            <img width={8} height={8} alt='close-icon' src={CloseIcon} />
          </button>
        )}
        {routes.length > 5 && !isExpanded && (
          <div
            id={`tasks-count_${latLngId}`}
            data-id={latLngId}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px',
              color: '#33363D',
              background: 'white',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            {`${routes.length} tasks`}
          </div>
        )}

        <ul
          style={{
            padding: 0,
            margin: 0,
            boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
            ...(routes.length > 5 && { maxHeight: 170, overflow: 'auto' }),
            ...(routes.length > 5 && !isExpanded && { display: 'none' }),
          }}
        >
          {routes.map((route, idx) => (
            <div
              style={{ position: 'relative' }}
              key={route.id}
              data-id={route.task_id}
            >
              {route.is_alerted && (
                <div
                  className='alerted-dot'
                  style={{
                    width: 34,
                    height: 34,
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                />
              )}

              <li
                style={{
                  zIndex: 2,
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                  padding: '5px 10px 5px 5px',
                  color: 'white',
                  background: 'white',
                  position: 'relative',
                  ...(route.is_disabled && {
                    justifyContent: 'center',
                  }),
                  ...(idx === 0 && {
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }),
                  ...(routes.length === idx + 1 && {
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                  }),
                  ...(idx === 0 &&
                    isExpanded &&
                    routes.length > 5 && {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }),
                }}
              >
                {route.is_disabled && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'white',
                      opacity: 0.7,
                    }}
                  />
                )}
                <div
                  id={route.is_disabled ? '' : route.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    background: 'white',
                    width: 22,
                    height: 22,
                    marginRight: 5,
                    border: '1px solid #121142',
                    ...(route.update_sort_id && {
                      background: '#121142',
                      color: 'white',
                      fontWeight: 400,
                      fontSize: 14,
                    }),
                    ...(route.is_disabled && {
                      cursor: 'default',
                    }),
                    ...(route.is_disabled && {
                      width: 0,
                      borderColor: 'white',
                    }),
                  }}
                >
                  {route.update_sort_id}
                </div>
                <img
                  width={16}
                  height={16}
                  alt={route.marker_type}
                  src={getTaskIcon(route.marker_type, true)}
                />
                <span
                  style={{ marginLeft: '5px', fontSize: 14, color: '#33363D' }}
                >
                  {route.task_name}
                </span>
              </li>
            </div>
          ))}
        </ul>
      </>,
    );
  } else {
    container.style.background = 'transparent';
    const hasSortId = opts.isOnlyOneRoute
      ? false
      : routes.some((route) => route.sort_id);
    const isAlertPosition = routes.filter((r) => r.is_alerted).length > 0;
    container.innerHTML = ReactDOMServer.renderToString(
      <div
        className={isAlertPosition ? 'alerted-dot' : ''}
        style={{
          border: '1px solid white',
          color: 'white',
          background: '#160E4D',
          height: 30,
          minWidth: 30,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 16,
          fontWeight: 500,
          ...(routes.length > 1 && {
            borderRadius: '20px',
            padding: '0px 5px',
          }),
          ...(!hasSortId && {
            fontSize: 12,
            fontWeight: 400,
            padding: '0px',
            borderRadius: '20px',
            width: 30,
          }),
        }}
      >
        {hasSortId
          ? routes
              .map((route) => route.sort_id)
              .filter((e) => !!e)
              .join(',')
              .replace(/,$/, '')
          : null}
      </div>,
    );
  }

  return container;
};

export const DriverRouteMarker = (
  routes: IDriverRoute[],
  opts: {
    isOnManageRoutes?: boolean;
    isExpanded?: boolean;
    latLngId?: string;
    isOnlyOneRoute?: boolean;
  },
) => {
  const hasSortId = opts.isOnlyOneRoute
    ? false
    : routes.some((route) =>
        opts.isOnManageRoutes ? route.update_sort_id : route.sort_id,
      );

  const isAlertPosition = routes.filter((r) => r.is_alerted).length > 0;

  const container = document.createElement('div');
  container.style.display = 'flex';
  container.style.justifyContent = 'center';
  container.style.alignItems = 'center';
  container.style.overflow = 'hidden';
  container.style.fontFamily = 'DM Sans';
  container.style.background = '#160E4D';
  container.style.height = '28px';
  container.style.minWidth = '28px';
  container.style.borderRadius = '50%';
  container.style.color = 'white';
  container.style.fontWeight = '500';
  container.style.fontSize = '16px';
  container.style.border = '1px solid white';
  container.className = isAlertPosition ? 'alerted-dot-drag-drop' : '';

  if (routes.length > 1 && routes.filter((r) => !!r.sort_id).length > 1) {
    container.style.borderRadius = '15px';
    container.style.padding = '0px 5px';
  }

  container.innerHTML = hasSortId
    ? routes
        .map((route) =>
          opts.isOnManageRoutes ? route.update_sort_id : route.sort_id,
        )
        .filter((e) => !!e)
        .join(',')
        .replace(/,$/, '')
    : null;

  return container;
};

export const HeatmapMarkerContent = (weight: number = 1) => {
  const container = document.createElement('div');
  container.style.cssText = 'position:absolute;';
  const color = weight > 10 ? '#ff0000' : '#0000ff';
  container.innerHTML = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity=".6" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" />circle cx="120" cy="120" opacity=".1" r="130" /></svg><div style="position:absolute;left: 50%; top: 47%; transform: translate(-50%,-50%);;font-size:11px;color:rgba(255,255,255,0.9);">${weight}</div>`;

  return container;
};
