import { FieldArray, Form, Formik, getIn } from 'formik';
import Modal from '~/components/common/Modal';
import {
  AddSpecialHours,
  AddHours,
  AddHoursDisableDelivery,
  AddSpecialHoursDisableDelivery,
} from '~/utils/schema/integration';
import { Field } from '~/components/common/Formik';
import {
  Divider,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import themes from '~/themes';
import moment from 'moment';
import {
  GAP_TIME_OPTIONS,
  TIME_SLOT_OPTIONS,
  TIME_SLOT_TYPE,
} from '~/constants/stores';
import { isArray, isEmpty, isString } from 'lodash';
import { useGenTimeSlot } from '~/services/api/stores';
import { fromQueryString } from '~/utils/queryString';
import { useLocation } from 'react-router-dom';
import InfoIcon from '~/assets/images/icons/info-gray.svg';
import Tooltip from '~/components/common/Tooltip';
import Button from '~/components/common/Button';
import AddIcon from '~/assets/images/icons/add-black.svg';
import DeleteIcon from '~/assets/images/icons/delete-bin-line.png';
import { HourError } from './DeliveryHoursSelect';
import { HoursSelect } from './HoursSelect';

export type DeliveryHoursSelectItem = {
  weekdayValue: string;
  weekDayLabel: string;
  totalTimeSlot: number;
  available: boolean;
  name?: string;
  time_slot_generation: string;
  from_time: string;
  to_time: string;
  start_date?: string;
  end_date?: string;
  delivery_hours?: number;
  delivery_window?: number;
  next_available_time?: number;
  lead_time?: number;
  last_order_time?: string;
  time_slot?: Array<{ from_time: string; to_time: string; lead_time?: number }>;
};

interface DialogAddTimeSlotsProps {
  open: boolean;
  onClose: () => void;
  onSave: (val: any) => void;
  data?: Array<DeliveryHoursSelectItem>;
  isSpecial?: boolean;
  store: any;
  enableDelivery: boolean;
  invalidDays?: any;
  isBulkSelect?: boolean;
}

const defaultCustomizeTimeSlot = {
  from_time: '09:00',
  to_time: '21:00',
  lead_time: 0,
};

export const DialogAddTimeSlotsHours: React.FC<DialogAddTimeSlotsProps> = ({
  open,
  onClose,
  onSave,
  data,
  isSpecial = false,
  store,
  enableDelivery,
  invalidDays,
  isBulkSelect = false,
}) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const storeID = search.id as string;
  const formRef = useRef(null);
  const [dateError, setDateError] = useState('');
  const [errors, setErrors] = useState<Array<HourError>>([]);
  const prevValues = useRef<any>();
  const [dirtyForm, setDirtyForm] = useState(false);

  const { mutate: getTimeSlots, isLoading } = useGenTimeSlot({
    onSuccess: (res) => {
      const { delivery_hours, special_hours } = res;
      if (isSpecial) {
        onSave({
          ...data[0],
          ...prevValues.current,
          ...special_hours[0],
        });
      } else {
        onSave({
          ...data[0],
          ...prevValues.current,
          time_slot: delivery_hours[data[0].weekdayValue].time_slot,
        });
      }

      onClose();
    },
  });

  const migrateDeliveryHours = (startDate, endDate, time_slot) => {
    const dateObject = {};
    const currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      dateObject[currentDate.format('YYYY-MM-DD')] = {
        time_slot,
      };
      currentDate.add(1, 'day');
    }

    return dateObject;
  };

  const onSubmit = (values) => {
    if (
      !values.available ||
      !enableDelivery ||
      values.time_slot_generation === TIME_SLOT_TYPE.NONE ||
      (values.time_slot_generation === TIME_SLOT_TYPE.CUSTOM && !isSpecial)
    ) {
      onSave({ ...data[0], ...values });
      return onClose();
    }

    if (values.time_slot_generation === TIME_SLOT_TYPE.CUSTOM && isSpecial) {
      const delivery_hours = migrateDeliveryHours(
        values.start_date,
        values.end_date,
        values.time_slot,
      );
      onSave({ ...data[0], ...values, delivery_hours });
      return onClose();
    }

    prevValues.current = values;
    const newStore = { ...store };
    if (isSpecial) {
      if (!values.start_date || !values.end_date) {
        return setDateError('Please enter Start Date or End Date');
      }

      values.start_date = moment(values.start_date)
        .local()
        .format('YYYY-MM-DD');
      values.end_date = moment(values.end_date).local().format('YYYY-MM-DD');
      newStore.special_hours = [{ ...values }];
    } else {
      data?.forEach((day) => {
        newStore.delivery_hours[day.weekdayValue] = {
          ...day,
          ...values,
        };
      });
    }

    return getTimeSlots({ ...newStore, id: storeID });
  };

  const handleSetErrorHours = (
    id: string,
    rangeTime: { from: string; to: string },
  ) => {
    let error = '';
    if (
      (rangeTime.from === '12:00' && rangeTime.to === '12:00') ||
      moment(rangeTime.to, 'hh:mm').isSameOrBefore(
        moment(rangeTime.from, 'hh:mm'),
      )
    ) {
      error = 'Start time must be before end time';
    }
    setErrors((errs) =>
      errs.length && errs.find((err) => err.id === id)
        ? errs.map((err) => (err.id === id ? { id, error } : err))
        : [...errs, { id, error }],
    );
  };

  const initialValues = useMemo(() => {
    if (enableDelivery) {
      if (isEmpty(data) || data?.length > 1)
        return {
          available: true,
          time_slot_generation: 'auto',
          start_date: '',
          end_date: '',
          delivery_window: 60,
          next_available_time: 60,
          from_time: '09:00',
          to_time: '21:00',
          lead_time: 0,
          name: '',
          last_order_time: '23:59',
          time_slot: [defaultCustomizeTimeSlot],
        };
      const timeSlotSelected = data[0];
      const {
        available,
        time_slot_generation,
        start_date,
        end_date,
        delivery_window,
        next_available_time,
        lead_time,
        from_time,
        to_time,
        name,
        last_order_time,
        time_slot,
        delivery_hours,
      } = timeSlotSelected;
      if (isSpecial) {
        return {
          available,
          time_slot_generation: time_slot_generation || 'auto',
          start_date,
          end_date,
          delivery_window,
          next_available_time,
          lead_time,
          from_time,
          to_time,
          name,
          last_order_time: last_order_time || '23:59',
          time_slot:
            time_slot_generation === TIME_SLOT_TYPE.CUSTOM
              ? delivery_hours[start_date]?.time_slot
              : [defaultCustomizeTimeSlot],
        };
      }
      return {
        available,
        time_slot_generation,
        delivery_window,
        next_available_time,
        lead_time,
        from_time,
        to_time,
        last_order_time: last_order_time || '23:59',
        time_slot:
          time_slot_generation === TIME_SLOT_TYPE.CUSTOM
            ? time_slot
            : [defaultCustomizeTimeSlot],
      };
    }

    if (isEmpty(data) || data?.length > 1) {
      return isSpecial
        ? {
            name: '',
            start_date: '',
            end_date: '',
            available: true,
            last_order_time: '23:59',
          }
        : {
            available: true,
            last_order_time: '23:59',
          };
    }
    const timeSlotSelected = data[0];
    const { available, last_order_time, name, start_date, end_date } =
      timeSlotSelected;
    if (isSpecial) {
      return {
        name,
        start_date,
        end_date,
        available,
        last_order_time: last_order_time || '23:59',
      };
    }
    return {
      available,
      last_order_time: last_order_time || '23:59',
    };
  }, [data, isSpecial, enableDelivery]);

  const title = useMemo(() => {
    if (isSpecial) {
      return isEmpty(data) ? 'Add Special Hours' : data[0]?.name;
    }
    return data?.map((day) => day?.weekDayLabel)?.join(', ') || '';
  }, [data]);

  const error = errors?.find((e) => e.id === `auto`);

  const validationSchema = useMemo(() => {
    if (!enableDelivery) {
      return isSpecial
        ? AddSpecialHoursDisableDelivery
        : AddHoursDisableDelivery;
    }
    return isSpecial ? AddSpecialHours : AddHours;
  }, [isSpecial, enableDelivery]);

  const renderDisableDelivery = ({
    values,
    setFieldValue,
    errors: formErrors,
    touched,
  }) => (
    <>
      <Stack flexDirection='column' gap={1}>
        <Typography color={themes.color.black}>Availability</Typography>
        <Stack flexDirection='row' gap={0.5} alignItems='center'>
          <Field.SwitchButton noText name='available' />
          Allow same day service during the following hours. Orders placed
          outside the hours will be processed in the next available day.
        </Stack>
      </Stack>
      {values.available && (
        <Stack mt={1.6}>
          <HoursSelect
            legend='Last Order Time'
            timeGap={15}
            value={values.last_order_time}
            onChange={(e: any) => {
              const { value: last_order_time } = e.target;
              setFieldValue('last_order_time', last_order_time);
            }}
            error={
              !!error?.error ||
              (!!getIn(touched, 'last_order_time') &&
                !!getIn(formErrors, 'last_order_time'))
            }
            helperText={
              getIn(touched, 'last_order_time') &&
              getIn(formErrors, 'last_order_time')
            }
          />
        </Stack>
      )}
    </>
  );

  const renderGenerate = ({
    values,
    setFieldValue,
    errors: formErrors,
    touched,
  }) => (
    <Stack
      flexDirection='column'
      gap={1.6}
      sx={{
        background: themes.bg.lightPurple,
        p: 1.6,
        mt: 1.6,
      }}
    >
      <Stack direction='column'>
        <Stack
          direction='row'
          gap={1.6}
          sx={{
            [themes.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <HoursSelect
            legend='Start Time'
            timeGap={15}
            value={values.from_time}
            onChange={(e: any) => {
              const { value: from_time } = e.target;
              setFieldValue('from_time', from_time);
              handleSetErrorHours('auto', {
                from: from_time,
                to: values.to_time,
              });
            }}
            error={
              !!getIn(touched, 'from_time') && !!getIn(formErrors, 'from_time')
            }
            helperText={
              getIn(touched, 'from_time') && getIn(formErrors, 'from_time')
            }
          />
          <HoursSelect
            legend='End Time'
            timeGap={15}
            value={values.to_time}
            onChange={(e: any) => {
              const { value: to_time } = e.target;
              setFieldValue('to_time', to_time);
              handleSetErrorHours('auto', {
                from: values.from_time,
                to: to_time,
              });
            }}
            error={
              !!getIn(touched, 'to_time') && !!getIn(formErrors, 'to_time')
            }
            helperText={
              getIn(touched, 'to_time') && getIn(formErrors, 'to_time')
            }
          />
        </Stack>
        {error?.error ? (
          <FormHelperText error={!!error.error} sx={{}}>
            {error.error}
          </FormHelperText>
        ) : null}
      </Stack>

      <Field.Select
        fullWidth
        name='delivery_window'
        options={GAP_TIME_OPTIONS.map((o) => ({
          value: o.value,
          label: `${o.label}`,
        }))}
        formControlProps={{
          sx: {
            'mt': 1,
            '& > div': {
              background: 'white',
            },
          },
        }}
        legend='Each Time Slot Duration'
      />
      <Field.Select
        fullWidth
        name='next_available_time'
        options={GAP_TIME_OPTIONS}
        formControlProps={{
          sx: {
            'mt': 1,
            '& > div': {
              background: 'white',
            },
          },
        }}
        legend='Next Available Time'
      />
      <Field.Select
        fullWidth
        name='lead_time'
        options={[
          {
            value: 0,
            label: 'None',
          },
          ...GAP_TIME_OPTIONS,
        ]}
        formControlProps={{
          sx: {
            'mt': 1,
            '& > div': {
              background: 'white',
            },
          },
        }}
        legend={
          <Stack flexDirection='row' alignItems='center' gap={0.5}>
            Lead Time (Optional){' '}
            <Tooltip
              title={
                <Typography sx={{ whiteSpace: 'break-spaces' }}>
                  {
                    'If customers order after the lead time,\nthe time slot won’t be shown.'
                  }
                </Typography>
              }
              arrow
            >
              <img
                src={InfoIcon}
                alt=''
                style={{
                  marginBottom: '2px',
                }}
              />
            </Tooltip>
          </Stack>
        }
      />
    </Stack>
  );

  const renderCustom = ({
    values,
    setFieldValue,
    errors: formErrors,
    touched,
  }) => {
    const { time_slot } = values;

    const helperText =
      isArray(getIn(touched, 'time_slot')) &&
      isString(getIn(formErrors, 'time_slot'))
        ? getIn(formErrors, 'time_slot')
        : '';

    return (
      <Stack
        flexDirection='column'
        gap={1.6}
        sx={{
          background: themes.bg.lightPurple,
          p: 1.6,
          mt: 1.6,
        }}
      >
        <Stack direction='column'>
          <Typography color={themes.color.black}>Time slots</Typography>
          <FieldArray name='time_slot'>
            {({ remove, push }) => (
              <Stack>
                {time_slot?.map(({ from_time, to_time, lead_time }, index) => (
                  <Stack
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    direction='row'
                    gap={1.6}
                    sx={{
                      [themes.breakpoints.down('md')]: {
                        flexDirection: 'column',
                      },
                      mt: 1.6,
                    }}
                  >
                    <HoursSelect
                      legend={
                        index === 0 ? (
                          <Typography
                            variant='body2'
                            sx={{
                              color: '#8C95BA',
                              pb: 1.5,
                            }}
                          >
                            Start Time
                          </Typography>
                        ) : (
                          ''
                        )
                      }
                      timeGap={15}
                      value={from_time}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue(`time_slot[${index}].from_time`, value);
                      }}
                      error={
                        !!getIn(touched, `time_slot[${index}].from_time`) &&
                        !!getIn(formErrors, `time_slot[${index}].from_time`)
                      }
                      helperText={
                        getIn(touched, `time_slot[${index}].from_time`) &&
                        getIn(formErrors, `time_slot[${index}].from_time`)
                      }
                    />
                    <HoursSelect
                      legend={
                        index === 0 ? (
                          <Typography
                            variant='body2'
                            sx={{
                              color: '#8C95BA',
                              pb: 1.5,
                            }}
                          >
                            End Time
                          </Typography>
                        ) : (
                          ''
                        )
                      }
                      timeGap={15}
                      value={to_time}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue(`time_slot[${index}].to_time`, value);
                      }}
                      error={
                        !!getIn(touched, `time_slot[${index}].to_time`) &&
                        !!getIn(formErrors, `time_slot[${index}].to_time`)
                      }
                      helperText={
                        getIn(touched, `time_slot[${index}].to_time`) &&
                        getIn(formErrors, `time_slot[${index}].to_time`)
                      }
                    />
                    <Field.Select
                      fullWidth
                      name={`time_slot[${index}].lead_time`}
                      value={lead_time}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        setFieldValue(
                          `time_slot[${index}].lead_time`,
                          value || 0,
                        );
                      }}
                      options={[
                        {
                          value: 0,
                          label: 'None',
                        },
                        ...GAP_TIME_OPTIONS,
                      ]}
                      formControlProps={{
                        sx: {
                          '& > div': {
                            background: 'white',
                          },
                        },
                      }}
                      legend={
                        index === 0 ? (
                          <Stack
                            flexDirection='row'
                            alignItems='center'
                            gap={0.5}
                            sx={{
                              pb: 1.5,
                            }}
                          >
                            <Typography
                              variant='body2'
                              sx={{
                                color: '#8C95BA',
                              }}
                            >
                              Lead Time (Optional){' '}
                            </Typography>
                            <Tooltip
                              title={
                                <Typography sx={{ whiteSpace: 'break-spaces' }}>
                                  {
                                    'If customers order after the lead time,\nthe time slot won’t be shown.'
                                  }
                                </Typography>
                              }
                              arrow
                            >
                              <img
                                src={InfoIcon}
                                alt=''
                                style={{
                                  marginBottom: '2px',
                                }}
                              />
                            </Tooltip>
                          </Stack>
                        ) : (
                          ''
                        )
                      }
                    />
                    {time_slot?.length !== 1 && (
                      <Stack
                        onClick={() => remove(index)}
                        sx={{
                          pt: index === 0 ? '46px' : '8px',
                          cursor: 'pointer',
                        }}
                        flexDirection='column'
                      >
                        <img
                          src={DeleteIcon}
                          alt=''
                          style={{
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </Stack>
                    )}
                  </Stack>
                ))}
                <Stack flexDirection='row' gap={1.6}>
                  <Button
                    noRounder
                    buttonType='default'
                    sx={{
                      mt: 2.5,
                      gap: '4px',
                      width: 'fit-content',
                    }}
                    onClick={() => {
                      push(defaultCustomizeTimeSlot);
                    }}
                  >
                    <img src={AddIcon} alt='' />
                    Add Slot
                  </Button>
                </Stack>
              </Stack>
            )}
          </FieldArray>
          {helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </Stack>
      </Stack>
    );
  };

  const renderNone = ({
    values,
    setFieldValue,
    touched,
    errors: formErrors,
  }) => (
    <Stack mt={1.6}>
      <HoursSelect
        legend='Last Order Time'
        timeGap={15}
        value={values.last_order_time}
        onChange={(e: any) => {
          const { value: last_order_time } = e.target;
          setFieldValue('last_order_time', last_order_time);
        }}
        error={
          !!getIn(touched, 'last_order_time') &&
          !!getIn(formErrors, 'last_order_time')
        }
        helperText={
          getIn(touched, 'last_order_time') &&
          getIn(formErrors, 'last_order_time')
        }
      />
    </Stack>
  );

  const renderEnableDelivery = ({
    values,
    setFieldValue,
    errors: formErrors,
    touched,
  }) => {
    const { time_slot_generation } = values;

    let contentComponent;
    switch (time_slot_generation) {
      case TIME_SLOT_TYPE.AUTO: {
        contentComponent = renderGenerate({
          values,
          setFieldValue,
          errors: formErrors,
          touched,
        });
        break;
      }

      case TIME_SLOT_TYPE.CUSTOM: {
        contentComponent = renderCustom({
          values,
          setFieldValue,
          errors: formErrors,
          touched,
        });
        break;
      }

      case TIME_SLOT_TYPE.NONE: {
        contentComponent = renderNone({
          values,
          setFieldValue,
          errors: formErrors,
          touched,
        });
        break;
      }

      default:
        break;
    }

    return (
      <>
        <Stack flexDirection='column' gap={1}>
          <Typography color={themes.color.black}>Availability</Typography>
          <Stack flexDirection='row' gap={0.5} alignItems='center'>
            <Field.SwitchButton noText name='available' />
            Allow customers to choose a time slot.
          </Stack>
        </Stack>
        {values.available && (
          <>
            <Stack mt={1.6}>
              <Field.Select
                fullWidth
                name='time_slot_generation'
                options={TIME_SLOT_OPTIONS.map((o) => ({
                  value: o.value,
                  label: `${o.label}`,
                }))}
                formControlProps={{
                  sx: { width: '100%', mt: 1 },
                }}
                onChange={() => {}}
                legend='Time Slot Option'
              />
            </Stack>
            {contentComponent}
          </>
        )}
      </>
    );
  };

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      disableCloseOutside
      PaperProps={{
        sx: {
          width: 650,
        },
      }}
      maxWidth='lg'
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          onClick: () => formRef.current?.handleSubmit(),
          loading: isLoading,
          disabled:
            !!errors.filter((err) => !!err.error).length ||
            (!dirtyForm && !isBulkSelect),
        },
      ]}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        innerRef={formRef}
      >
        {({ values, setFieldValue, errors: formErrors, touched, dirty }) => {
          setDirtyForm(dirty);
          return (
            <Form
              style={{ minHeight: 520 }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              {isSpecial && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field.TextInput name='name' legend='Name' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field.DateSelector
                        legend='Start Date'
                        name='start_date'
                        dateFormat
                        disablePastDate
                        timeFormat={false}
                        invalidDays={invalidDays}
                        onApply={(_, val) => {
                          setFieldValue(
                            'start_date',
                            moment(val).local().format('YYYY-MM-DD'),
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field.DateSelector
                        name='end_date'
                        legend='End Date'
                        dateFormat
                        disablePastDate
                        invalidDays={invalidDays}
                        onApply={(_, val) => {
                          setFieldValue(
                            'end_date',
                            moment(val).local().format('YYYY-MM-DD'),
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  {dateError ? (
                    <FormHelperText error>{dateError}</FormHelperText>
                  ) : null}
                  <Divider sx={{ my: 2 }} />
                </>
              )}
              {enableDelivery
                ? renderEnableDelivery({
                    values,
                    setFieldValue,
                    errors: formErrors,
                    touched,
                  })
                : renderDisableDelivery({
                    values,
                    setFieldValue,
                    errors: formErrors,
                    touched,
                  })}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
