import { Box, Stack } from '@mui/material';
import { styled } from '~/themes';

export const StyledContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'expanded' && prop !== 'openedDetail',
})<{ expanded?: boolean; openedDetail?: boolean }>(
  ({ theme, expanded, openedDetail }) => ({
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    width: 360,
    height: 72,
    background: theme.bg.lightPurple,
    borderRadius: '10px',
    padding: theme.spacing(2),
    overflow: 'hidden',
    ...(expanded && {
      height: 'calc(100% - 80px) !important',
    }),
    [theme.breakpoints.between('lg', 'ss')]: {
      height: 112,
      boxSizing: 'border-box',
      width: 'calc(100% - 40px)',
      maxWidth: 400,
      ...(openedDetail && { display: 'none' }),
      ...(expanded && {
        height: 'calc(100% - 40px) !important',
      }),
    },
    [theme.breakpoints.down('lg')]: {
      height: 112,
      boxSizing: 'border-box',
      width: 'calc(100% - 40px)',
      maxWidth: 400,
      ...(openedDetail && { display: 'none' }),
      ...(expanded && {
        height: 'calc(100% - 40px) !important',
      }),
    },
  }),
);

export const StyledBottomButton = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  height: 20,
  background: theme.bg.midPurple,
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const StyledTaskDetail = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(44),
  top: theme.spacing(2),
  width: 400,
  background: theme.bg.lightPurple,
  borderRadius: '10px',
  overflow: 'hidden',
  height: 'calc(100% - 40px)',
  [theme.breakpoints.between('lg', 'ss')]: {
    maxWidth: 400,
    zIndex: 1,
    top: theme.spacing(2),
    left: theme.spacing(2),
    boxSizing: 'border-box',
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 40px)',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 400,
    zIndex: 1,
    top: theme.spacing(2),
    left: theme.spacing(2),
    boxSizing: 'border-box',
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 40px)',
  },
}));

export const StyledTaskLogItem = styled('div')(({ theme }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'marginBottom': theme.spacing(1),
  'position': 'relative',
  '&:before': {
    content: '""',
    width: 1,
    height: 'calc(50% + 20px)',
    backgroundColor: theme.bg.purpleOutline,
    position: 'absolute',
    left: 4,
    bottom: '50%',
    zIndex: 0,
  },
  '&:after': {
    content: '""',
    width: 1,
    height: 'calc(50% + 20px)',
    backgroundColor: theme.bg.purpleOutline,
    position: 'absolute',
    left: 4,
    top: '50%',
    zIndex: 0,
  },
  '&:last-child': {
    'marginBottom': 0,
    '&::after': {
      display: 'none',
    },
  },
  '&:first-of-type': {
    '&::before': {
      display: 'none',
    },
  },
}));

export const StyledTaskLogPoint = styled('div')(({ theme }) => ({
  padding: '4px',
  borderRadius: '50%',
  backgroundColor: theme.bg.purpleOutline,
  marginRight: theme.spacing(1),
  zIndex: 1,
}));

export const StyledTaskStepBody = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'hiddenDashed',
})<{ hiddenDashed?: boolean }>(({ theme, hiddenDashed = false }) => ({
  'flexDirection': 'column',
  'marginLeft': `10px !important`,
  'paddingLeft': theme.spacing(2),
  'borderLeft': '1px dashed #C9C9C9',
  '& > div:last-child': {
    marginBottom: theme.spacing(1),
  },
  ...(hiddenDashed && {
    borderLeft: 'none',
  }),
}));
export const StyledTaskStepTitle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.color.black,
  paddingLeft: theme.spacing(0.5),
}));

export const StyledTaskStepIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'borderColor',
})<{ borderColor?: string }>(({ theme, borderColor }) => ({
  display: 'inline-block',
  width: 5,
  height: 5,
  border: `5px solid ${borderColor || theme.color.violet900}`,
  borderRadius: 10,
  marginRight: theme.spacing(1),
  marginLeft: 5,
}));

export const StyledLightPurpleBg = styled(Stack)(({ theme }) => ({
  background: theme.bg.gray300,
  borderRadius: 5,
  padding: theme.spacing(1),
  flexDirection: 'row',
  alignItems: 'center',
}));

export const StyledTaskImageContainer = styled('div')(({ theme }) => ({
  'position': 'relative',
  'width': 100,
  'height': 80,
  '&:hover': {
    '& > .bg-img-hover': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.color.black,
      opacity: '0.8',
      color: theme.color.white,
    },
  },
  '& > img': {
    width: 100,
    height: 80,
    cursor: 'pointer',
    objectFit: 'cover',
  },
}));

export const StyledTaskImageDetail = styled('img')(() => ({
  maxHeight: '540px !important',
  maxWidth: '540px !important',
  width: 'auto',
  height: 'auto',
  objectFit: 'contain',
}));

export const StyledSelectedItem = styled(Box, {
  shouldForwardProp: (props) => props !== 'isPickup' && props !== 'isDriver',
})<{ isPickup?: boolean; isDriver?: boolean }>(
  ({ theme, isPickup, isDriver }) => ({
    position: 'absolute',
    top: '16px',
    left: '0',
    height: '50px',
    width: '5px',
    background: theme.bg.white,
    borderRadius: '2px',
    ...(isPickup && {
      height: '80px',
      top: '12px',
    }),
    ...(isDriver && {
      height: 'calc(100% - 14px)',
      top: '7px',
    }),
  }),
);

export const StyledItemContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  'background': theme.bg.white,
  'borderRadius': '10px',
  'padding': theme.spacing(1.5),
  'cursor': 'pointer',
  'position': 'relative',
  '&:hover': {
    '& > .selected-task': {
      background: theme.bg.darkPurple,
    },
  },
  ...(isSelected && {
    '& > .selected-task': {
      background: theme.bg.darkPurple,
    },
  }),
  'marginBottom': '10px !important',
  'color': '#33363D',
}));
