import {
  Box,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
} from '@mui/material';
import Button from '~/components/common/Button';
import { Icon } from '~/components/common/Icon';
import { SearchInput } from '~/components/common/Search';
import themes, { styled } from '~/themes';
import { memo, useMemo, useState } from 'react';
import Close from '@mui/icons-material/Close';
import GroupRadioButton from '~/components/common/GroupRadioButton';
import { Select } from '~/components/common/Select';
import { Driver, LocationInfo } from '~/pages/LiveTracking/types';
import { ONGOING_STATUS_OPTIONS } from '~/constants/task';
import Tooltip from '~/components/common/Tooltip';
import SearchIcon from '~/assets/images/icons/search-line.svg';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  'width': theme.spacing(2.2),
  'height': theme.spacing(2.2),
  'marginRight': theme.spacing(-0.8),
  '& > img': {
    width: 16,
    height: 16,
  },
}));

type FiltersProps = {
  drivers: Driver[];
  isFiltered?: boolean;
  isHidden?: boolean;
  storeLocations?: LocationInfo[];
  onApply: (val: any) => void;
  onClear: () => void;
  onApplySearch: (val: any) => void;
  onClearSearch: () => void;
};

const defaultFilters = {
  sort_by: '',
  pickup_hub_id: '',
  executor_ids: '',
  statuses: '',
  sort: -1,
  search: '',
};

export const Filters: React.FC<FiltersProps> = memo(
  ({
    drivers,
    isFiltered,
    isHidden,
    storeLocations,
    onApply,
    onClear,
    onApplySearch,
    onClearSearch,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [filters, setFilters] = useState(defaultFilters);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const menuFilterComponent = useMemo(
      () => (
        <Menu
          id='filter-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              sx: {
                width: 230,
                boxShadow: '0px 1px 6px #160E4D26',
                borderRadius: '18px',
                px: 2,
                py: 1,
                position: 'relative',
              },
            },
          }}
        >
          <Typography variant='h4'>Sort by:</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              background: themes.bg.midPurple,
              borderRadius: '50%',
              p: 1,
              width: 15,
              height: 15,
              position: 'absolute',
              top: 10,
              right: 10,
            }}
          >
            <Close
              sx={{ color: themes.color.violet900, width: 15, height: 15 }}
            />
          </IconButton>
          <GroupRadioButton
            sx={{
              'mt': 1,
              'gap': '0px 18px !important',
              '& > label': {
                border: 'none',
                padding: 0.5,
                fontWeight: 'normal',
              },
            }}
            value={filters.sort_by}
            onChange={(e) =>
              setFilters({ ...filters, sort_by: e.target.value })
            }
            options={[
              {
                value: 'expected_delivery_before',
                label: 'Schedule delivery time',
              },
              { value: 'integration_type', label: 'Task ID' },
            ]}
          />
          <Divider sx={{ my: 1.5 }} />
          <Stack spacing={2}>
            <Typography variant='h4'>Filters:</Typography>
            <Select
              options={ONGOING_STATUS_OPTIONS}
              value={filters.statuses}
              legend='Filter by task status'
              placeholder='All statuses'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFilters({
                  ...filters,
                  statuses: event.target.value,
                });
              }}
            />
            <Select
              options={drivers.map((driver) => ({
                value: driver.id,
                label: driver.display_name,
              }))}
              value={filters.executor_ids}
              legend='Filter by driver'
              placeholder='All drivers'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFilters({
                  ...filters,
                  executor_ids: event.target.value,
                });
              }}
            />

            <Select
              options={storeLocations.map((storeLocation) => ({
                value: storeLocation.id,
                label: storeLocation.name,
              }))}
              value={filters.pickup_hub_id}
              legend='Filter by store location'
              placeholder='All locations'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFilters({ ...filters, pickup_hub_id: event.target.value });
              }}
            />
            <Stack
              direction='row'
              spacing={1}
              sx={{
                button: {
                  minWidth: 'unset',
                },
              }}
            >
              <Button
                fullWidth
                rounder10
                buttonType='default'
                onClick={() => {
                  handleClose();
                  setFilters(defaultFilters);
                  onClear();
                }}
              >
                Clear
              </Button>
              <Button
                fullWidth
                rounder10
                buttonType='default'
                onClick={() => {
                  handleClose();
                  onApply(filters);
                }}
              >
                Apply
              </Button>
            </Stack>
          </Stack>
        </Menu>
      ),
      [filters, open, storeLocations, drivers],
    );

    const useTipComponent = useMemo(
      () => (
        <Tooltip
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: themes.bg.white,
                color: themes.color.black,
                maxWidth: 350,
                boxShadow: '0px 1px 6px #160E4D26',
              },
            },
          }}
          title={
            <Stack p={0.5} spacing={1}>
              <Stack direction='row' spacing={1}>
                <Icon name='sparkle' baseUrl='map' useBackgroundImg size={18} />
                <Typography color={themes.color.violet900} fontWeight={500}>
                  To select multiple tasks
                </Typography>
              </Stack>
              <Typography>Hold Command or Control and click</Typography>
            </Stack>
          }
          placement='bottom-start'
        >
          <Stack
            width={35}
            height={35}
            justifyContent='center'
            alignItems='center'
          >
            <Icon name='light' baseUrl='map' useBackgroundImg size={16} />
          </Stack>
        </Tooltip>
      ),
      [],
    );

    return (
      <Stack
        direction='row'
        spacing={1}
        sx={{ display: isHidden ? 'none' : 'flex' }}
      >
        <SearchInput
          placeholder='Search'
          searchSuggestion='Search'
          prefix={
            <StyledIconButton>
              <img alt='search-icon' src={SearchIcon} width={22} height={22} />
            </StyledIconButton>
          }
          sx={{
            'paddingLeft': '8px !important',
            'background': `${themes.bg.white} !important`,
            '& .MuiOutlinedInput-input': {
              '&::placeholder': {
                color: 'rgba(51, 54, 61, 0.40) !important',
              },
            },
            '& .MuiButtonBase-root': {
              marginRight: '4px !important',
            },
            '& .MuiInputAdornment-root': {
              marginRight: '0px !important',
              marginLeft: '0px !important',
            },
            '& input': {
              paddingRight: '0 !important',
            },
          }}
          onChange={(event) => {
            setFilters({ ...filters, search: event.target.value });
          }}
          value={filters.search}
          onClear={() => {
            setFilters({ ...filters, search: '' });
            onClearSearch();
          }}
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              onApplySearch(filters.search);
            }
          }}
          showSeachIcon={false}
        />
        <Box position='relative'>
          <Button
            buttonType='text'
            noRounder
            sx={{ minWidth: '35px !important' }}
            onClick={handleClick}
          >
            <Icon
              name='filter'
              baseUrl='map'
              color='#33363D'
              useBackgroundImg
              size={16}
            />
          </Button>
          {isFiltered ? (
            <Box
              sx={{
                position: 'absolute',
                width: 10,
                height: 10,
                top: 2,
                right: 2,
                background: '#25D000',
                borderRadius: '50%',
              }}
            />
          ) : (
            ''
          )}
        </Box>

        {menuFilterComponent}
        {useTipComponent}
      </Stack>
    );
  },
);
