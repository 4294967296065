/* eslint-disable no-return-assign */
import { useEffect, useRef } from 'react';
import ChartContainer from '~/components/common/Chart/ChartContainer';
import './index.scss';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { HeatmapMarkerContent } from '~/pages/LiveTracking/components/Markers/MarkerContents';
import { useHitMapContext } from '../../context/useHitMapContext';

type NewDropOffHitMapProps = {
  data: any;
  storeLocations: { lat: number; lng: number }[];
};

export const NewDropOffHitMap: React.FC<NewDropOffHitMapProps> = ({
  data,
  storeLocations,
}) => {
  const heatmapMarkersRef = useRef<any>(null);
  const { map, isMapLoaded, mapRef } = useHitMapContext();
  const heatMapData = data.map((item) => ({
    location: new google.maps.LatLng(item.lat, item.lng),
    weight: item.weight,
  }));

  const fillHeatMapData = () => {
    heatmapMarkersRef.current?.setMap(null);
    heatmapMarkersRef.current = null;
    const infoWindow = new google.maps.InfoWindow({
      content: '',
      disableAutoPan: true,
    });
    const newData = heatMapData.map((item) => ({
      ...item,
      count: item.weight,
    }));
    const markersList = newData.map(({ location: position, count }) => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: HeatmapMarkerContent(count),
        title: `${count}`,
      });

      marker.addListener('click', () => {
        infoWindow.open(map, marker);
      });
      return marker;
    });

    const bounds = new google.maps.LatLngBounds();
    heatMapData.forEach((item) => {
      bounds.extend(item.location);
    });
    map?.fitBounds(bounds);
    const renderer = {
      render: ({ markers, position }) => {
        const count = markers.reduce(
          (result, item) => (result += parseInt(item.title, 10)),
          0,
        );
        return new google.maps.marker.AdvancedMarkerElement({
          position,
          content: HeatmapMarkerContent(count),
        });
      },
    };

    const markerClusterer = new MarkerClusterer({
      markers: markersList,
      map,
      // @ts-ignore
      renderer,
    });

    heatmapMarkersRef.current = markerClusterer;
  };

  useEffect(() => {
    if (!isMapLoaded || !map) return;
    if (heatMapData.length) {
      fillHeatMapData();
      return;
    }
    heatmapMarkersRef.current?.setMap(null);
    heatmapMarkersRef.current = null;

    if (storeLocations.length) {
      const bounds = new google.maps.LatLngBounds();
      storeLocations.forEach((item) => {
        bounds.extend(item);
      });
      map?.fitBounds(bounds);
    }
  }, [heatMapData, isMapLoaded, map, storeLocations]);

  return (
    <ChartContainer
      title='Drop-off Heatmap'
      sxContainer={{
        height: 500,
      }}
      sxContent={{ mt: 1 }}
    >
      <div
        ref={mapRef}
        id='drop-of-heap-map'
        style={{ height: '100%', width: '100%', borderRadius: '20px' }}
      />
    </ChartContainer>
  );
};
