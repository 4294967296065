import moment, { Moment } from 'moment';
import DropOffIcon from '~/assets/images/map/drop-off-black.svg';
import ReturnIcon from '~/assets/images/map/return-black.svg';
import DropOffIconWhite from '~/assets/images/map/dropoff-green.svg';
import ReturnIconWhite from '~/assets/images/map/return-purple.svg';
import PickUpIconWhite from '~/assets/images/map/pickup-orange.svg';
import PickUpIcon from '~/assets/images/map/pickup-black.svg';
import { USER_ROLE } from '~/constants/userManagement';
import {
  getCountryCodeFromPhone,
  getPhoneWithCountryCode,
} from '~/utils/common';
import {
  CountryCode,
  isValidNumberForRegion,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { cloneDeep, isEqual, isNull, omitBy } from 'lodash';
import {
  formatTaskDateTime,
  getStateNameByStateCodeAndCountry,
} from '~/utils/formatter';
import {
  IAddTaskData,
  IDriverRoute,
  IDriverRouteResponse,
  ITaskAddress,
  LocationInfo,
  MarkerType,
  Task,
  TaskStatus,
} from './types';

export const formatDateTimeUTC = (
  date?: string | Date | Moment,
  format: string = 'hh:mm a',
) =>
  date
    ? moment(`${moment(date).format('YYYY-MM-DDTHH:mm:ss+00:00')}`).format(
        format,
      )
    : '-';

export const isInfoWindowOpen = (infoWindow) => {
  const map = infoWindow.getMap();
  return map !== null && typeof map !== 'undefined';
};

export const isValidLatLngLiteral = (obj: any): boolean =>
  obj &&
  typeof obj === 'object' &&
  'lat' in obj &&
  'lng' in obj &&
  typeof obj.lat === 'number' &&
  typeof obj.lng === 'number' &&
  obj.lat !== 0 &&
  obj.lng !== 0;

export const mappingTaskStatusData = {
  [TaskStatus.Unassigned]: {
    text: 'Unassigned',
    color: '#F75879',
  },
  [TaskStatus.PendingPickup]: {
    text: 'Pickup',
    color: '#F7982A',
  },
  [TaskStatus.Delivering]: {
    text: 'Dropoff',
    color: '#74D6BA',
  },
  [TaskStatus.Returning]: {
    text: 'Return',
    color: '#9B57D1',
  },
  [TaskStatus.Returned]: {
    text: 'Returned',
    color: '#9B57D1',
  },
  [TaskStatus.Completed]: {
    text: 'Completed',
    color: '#59C9A9',
  },
  [TaskStatus.Cancelled]: {
    text: 'Cancelled',
    color: '#797979',
  },
};

export const mapTaskLogCreatedByText = (
  role: string,
  is_created_by_system: boolean,
) => {
  if (is_created_by_system) return 'Breadstack Delivery';
  switch (role) {
    case USER_ROLE.OWNER:
    case USER_ROLE.ADMIN:
      return 'Admin';
    case USER_ROLE.DRIVER:
      return 'Driver';
    case USER_ROLE.DISPATCHER:
      return 'Dispatcher';
    case USER_ROLE.API_KEY:
      return 'Storefront';
    default:
      return '-';
  }
};

export const convertedLocation = (data: LocationInfo) =>
  [data?.address_1, data?.address_2, data?.city, data?.state, data?.postcode]
    .filter(Boolean)
    .join(', ');

const DriverProgressBarState = {
  ...TaskStatus,
  DeliveringLongTime: 'delivering-long-time',
};

export const getProgressBarOption = (task: Task) => {
  const dropOffEtaTime = `${
    task?.estimated_delivery_time > 0
      ? moment().add(task.estimated_delivery_time, 'm').format('hh:mm a')
      : '-'
  }`;

  const dropOffLongTimeEtaTime = `${
    task?.delivery_arr_time > 0
      ? moment().add(task.delivery_arr_time, 's').format('hh:mm a')
      : '-'
  }`;

  const pickUpEtaTime = task.pickup_arr_time
    ? moment().add(task.pickup_arr_time, 's').format('hh:mm a')
    : '-';

  const returnEtaTime = task.estimated_delivery_time
    ? moment().add(task.estimated_delivery_time, 's').format('hh:mm a')
    : '-';

  const DriverProgressBarOptions = {
    [DriverProgressBarState.Unassigned]: {
      value: 0,
      desc: 'Task has not been assigned',
      time: 'ETA -',
      styles: {
        leftBg: '#EDEDED',
        rightBg: '#EDEDED',
        navColor: '#797979',
      },
    },
    [DriverProgressBarState.PendingPickup]: {
      value: 33.33,
      desc: 'Picking up the package',
      time: `ETA ${pickUpEtaTime}`,
      styles: {
        leftBg: '#E2EFF9',
        rightBg: '#83CBFF',
        navColor: '#0897FF',
      },
    },
    [DriverProgressBarState.Delivering]: {
      value: 66.66,
      desc: 'Dropping off the package',
      time: `ETA ${dropOffEtaTime}`,
      styles: {
        leftBg: '#E2EFF9',
        rightBg: '#83CBFF',
        navColor: '#0897FF',
      },
    },
    [DriverProgressBarState.DeliveringLongTime]: {
      value: 50,
      desc: 'Making other stops along the way',
      time: `ETA ${dropOffLongTimeEtaTime}`,
      styles: {
        leftBg: '#EDEDED',
        rightBg: '#CBCBCB',
        navColor: '#797979',
      },
    },
    [DriverProgressBarState.Returning]: {
      value: 66.66,
      desc: 'Returning the package',
      time: `ETA ${returnEtaTime}`,
      styles: {
        leftBg: '#E2EFF9',
        rightBg: '#83CBFF',
        navColor: '#0897FF',
      },
    },
    [DriverProgressBarState.Completed]: {
      value: 100,
      desc: 'Delivery was completed',
      time: `At ${
        task.delivered_at ? formatDateTimeUTC(task.delivered_at) : '-'
      }`,
      styles: {
        leftBg: '#E2EFF9',
        rightBg: '#83CBFF',
        navColor: '#0897FF',
      },
    },
    [DriverProgressBarState.Returned]: {
      value: 100,
      desc: 'Package was returned',
      time: `At ${task.updated_at ? formatDateTimeUTC(task.updated_at) : '-'}`,
      styles: {
        leftBg: '#E2EFF9',
        rightBg: '#83CBFF',
        navColor: '#0897FF',
      },
    },
    [DriverProgressBarState.Cancelled]: {
      value: 100,
      desc: 'Delivery was cancelled',
      time: `At ${task.updated_at ? formatDateTimeUTC(task.updated_at) : '-'}`,
      styles: {
        leftBg: '#EDEDED',
        rightBg: '#EDEDED',
        navColor: '#797979',
      },
    },
  };

  if (
    task.status === TaskStatus.Delivering &&
    (!task.out_for_delivery_time || !task?.is_active)
  ) {
    return DriverProgressBarOptions[DriverProgressBarState.DeliveringLongTime];
  }

  return DriverProgressBarOptions[task.status];
};

export const getTaskIcon = (markerType: MarkerType, isWhiteIcon?: boolean) => {
  switch (markerType) {
    case MarkerType.Store:
      return isWhiteIcon ? PickUpIconWhite : PickUpIcon;
    case MarkerType.DropOff:
      return isWhiteIcon ? DropOffIconWhite : DropOffIcon;
    case MarkerType.Return:
      return isWhiteIcon ? ReturnIconWhite : ReturnIcon;
    default:
      return '';
  }
};

export const groupDriverRoutes = (
  driverRoutes: IDriverRouteResponse[],
  isEnabledDragDrop?: boolean,
): IDriverRoute[] =>
  driverRoutes.reduce((acc, route) => {
    const {
      id,
      name,
      pickup,
      delivery,
      pickup_index,
      delivery_index,
      return_index,
      pickup_arr_time,
      return_arr_time,
      expected_delivery_before,
      estimated_delivery_time,
      out_for_delivery_time,
      delivery_arr_time,
      status,
      pickup_alerted,
      delivery_alerted,
      is_active,
    } = route;

    const createTask = (markerType: MarkerType) => ({
      id: `${markerType}_${id}`,
      task_id: id,
      task_name: name,
      task_status: status,
      marker_type: markerType,
      update_sort_id: null,
      expected_delivery_before: expected_delivery_before
        ? `Sched ${formatTaskDateTime(expected_delivery_before, {
            sameDay: 'h:mm a',
          })}`
        : 'ASAP',
      estimated_delivery_time,
      ...(markerType === MarkerType.Store && {
        ...pickup,
        sort_id: pickup_index,
        update_sort_id: pickup_index,
        eta_time: pickup_arr_time,
        is_alerted: !!pickup_alerted,
        expected_delivery_before: '',
      }),
      ...(markerType === MarkerType.DropOff && {
        ...delivery,
        sort_id: delivery_index,
        update_sort_id: delivery_index,
        eta_time:
          out_for_delivery_time && is_active
            ? estimated_delivery_time * 60
            : delivery_arr_time,
        is_alerted: !!delivery_alerted,
      }),
      ...(markerType === MarkerType.Return && {
        ...pickup,
        sort_id: return_index,
        update_sort_id: return_index,
        eta_time: return_arr_time,
        is_alerted: false,
      }),
      ...(isEnabledDragDrop && {
        update_sort_id: null,
      }),
    });

    if (pickup && status === TaskStatus.PendingPickup) {
      acc.push(createTask(MarkerType.Store));
    }
    if (delivery && status !== TaskStatus.Returning) {
      acc.push(createTask(MarkerType.DropOff));
    }

    if (status === TaskStatus.Returning && pickup) {
      acc.push(createTask(MarkerType.Return));
    }

    return acc;
  }, []);

export const updateDriverRoutes = (
  driverRoutes: IDriverRoute[],
  updateRoute?: IDriverRoute,
) => {
  const { id, update_sort_id, task_id } = updateRoute || {};
  let updatedSortIdDriverRoutes;

  if (updateRoute?.id) {
    const updateSortIdItem = driverRoutes.find(
      (route) => !!route.update_sort_id && route.id === id,
    );

    if (updateSortIdItem) {
      let subtractionCount = 1;
      updatedSortIdDriverRoutes = driverRoutes.map((route) => {
        const updateItem = { ...route };
        if (updateItem.id === updateSortIdItem.id) {
          updateItem.update_sort_id = null;
        }

        if (
          updateSortIdItem.marker_type === MarkerType.Store &&
          updateItem.marker_type === MarkerType.DropOff &&
          updateItem.task_id === task_id
        ) {
          updateItem.update_sort_id = null;
          subtractionCount = 2;
        }

        if (
          updateItem.update_sort_id &&
          updateItem.update_sort_id > updateSortIdItem.update_sort_id
        ) {
          updateItem.update_sort_id -= subtractionCount;
        }
        return updateItem;
      });
    } else {
      updatedSortIdDriverRoutes = driverRoutes.map((task) => {
        const updateItem = { ...task };
        if (updateItem.id === id) {
          updateItem.update_sort_id = update_sort_id;
        }
        return updateItem;
      });
    }
  } else {
    updatedSortIdDriverRoutes = [...driverRoutes];
  }

  return updatedSortIdDriverRoutes.map((item) => {
    const updateItem = { ...item };

    if (item.marker_type === MarkerType.DropOff) {
      const pickupTask = updatedSortIdDriverRoutes.find(
        (t) => t.task_id === item.task_id && t.marker_type === MarkerType.Store,
      );
      if (pickupTask && pickupTask.update_sort_id === null) {
        updateItem.is_disabled = true;
      } else {
        updateItem.is_disabled = false;
      }
      return updateItem;
    }
    return updateItem;
  });
};

export const sortDriverRoutes = (
  driverRoutes: IDriverRoute[],
  opts?: {
    isOnManageRoutes?: boolean;
  },
) =>
  driverRoutes.sort((a, b) => {
    if (opts?.isOnManageRoutes) {
      if (a.update_sort_id !== null && b.update_sort_id !== null) {
        return a.update_sort_id - b.update_sort_id;
      }
      if (a.update_sort_id !== null) {
        return -1;
      }
      if (b.update_sort_id !== null) {
        return 1;
      }
    }

    if (a.sort_id !== null && b.sort_id !== null) {
      return a.sort_id - b.sort_id;
    }
    if (a.sort_id !== null) {
      return -1;
    }
    if (b.sort_id !== null) {
      return 1;
    }

    return 0;

    // Sort marker types: Store first, then DropOff, then Return
    // const markerTypeOrder = {
    //   [MarkerType.Store]: 1,
    //   [MarkerType.DropOff]: 2,
    //   [MarkerType.Return]: 3,
    // };

    // return markerTypeOrder[a.marker_type] - markerTypeOrder[b.marker_type];
  });

export const convertDriverRoutesToRequestParams = (routes: IDriverRoute[]) => {
  const routesGrouped = routes.reduce((acc, cur) => {
    const item = acc[cur.task_id] || {};
    const { marker_type, update_sort_id } = cur;
    const { pickup_index, delivery_index, return_index } = item;

    acc[cur.task_id] = {
      ...item,
      id: cur.task_id,
      task_id: cur.task_id,
      pickup_index:
        marker_type === MarkerType.Store
          ? update_sort_id
          : pickup_index || null,
      delivery_index:
        marker_type === MarkerType.DropOff
          ? update_sort_id
          : delivery_index || null,
      return_index:
        marker_type === MarkerType.Return
          ? update_sort_id
          : return_index || null,
    };

    return acc;
  }, {});

  return Object.values(routesGrouped);
};

export const updateOptimizedRoutesToDriverRoutes = (
  optimizedRoutes: IDriverRouteResponse[],
  routes: IDriverRoute[],
) =>
  routes.map((route) => {
    const updateRoute = optimizedRoutes.find((r) => {
      if (route.marker_type === MarkerType.Store) {
        return (
          r.id === route.task_id &&
          r.pickup_index &&
          !r.delivery_index &&
          !r.return_index
        );
      }
      if (route.marker_type === MarkerType.DropOff) {
        return (
          r.id === route.task_id &&
          !r.pickup_index &&
          r.delivery_index &&
          !r.return_index
        );
      }
      if (route.marker_type === MarkerType.Return) {
        return (
          r.id === route.task_id &&
          !r.pickup_index &&
          !r.delivery_index &&
          r.return_index
        );
      }
      return false;
    });
    if (updateRoute) {
      const { pickup_index, delivery_index, return_index } = updateRoute;
      return {
        ...route,
        eta_time: updateRoute.arr_time,
        update_sort_id: pickup_index || delivery_index || return_index,
      };
    }

    return {
      ...route,
      eta_time: null,
      update_sort_id: null,
    };
  });

export const groupDriverRoutesByPosition = (routes: IDriverRoute[]) =>
  routes.reduce((acc, route) => {
    const { lat, lng } = route;
    const key = `${lat}&${lng}`;
    acc[key] = acc[key] || [];
    acc[key].push(route);
    return acc;
  }, {});

export const groupStoreLocations = (locations: LocationInfo[]) =>
  locations.reduce((acc, location) => {
    const { lat, lng } = location;
    const key = `${lat}&${lng}`;
    acc[key] = acc[key] || [];
    acc[key].push(location);
    return acc;
  }, {});

export const groupTasksByPosition = (tasks: Task[]) =>
  tasks.reduce((acc, task) => {
    const { pickup, delivery, id, name, status } = task;

    // Helper function to add task information to the result array
    const addTask = (key, type) => {
      acc[key] = acc[key] || [];
      if (type === MarkerType.Store) {
        acc[key].push({
          id: `${type}_${id}`,
          task_id: id,
          task_name: name,
          marker_type: type,
          status,
          ...pickup,
        });
      } else {
        acc[key].push({
          id: `${type}_${id}`,
          task_id: id,
          task_name: name,
          marker_type: MarkerType.DropOff,
          status,
          ...delivery,
        });
      }
    };
    if (pickup?.lat && pickup?.lng) {
      addTask(`${pickup.lat}&${pickup.lng}`, MarkerType.Store);
    }

    if (delivery.lat && delivery.lng) {
      addTask(`${delivery.lat}&${delivery.lng}`, MarkerType.DropOff);
    }

    return acc;
  }, {});

export const formattedIdleTime = (start, end?: any) => {
  const momentStart = moment.utc(start);
  const momentEnd = end ? moment.utc(end) : moment.utc();
  const seconds = momentEnd.diff(momentStart, 'seconds');
  const minutes = momentEnd.diff(momentStart, 'minutes');
  const hours = momentEnd.diff(momentStart, 'hours');
  const days = momentEnd.diff(momentStart, 'days');
  const months = momentEnd.diff(momentStart, 'months');
  const years = momentEnd.diff(momentStart, 'years');

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''}`;
  }

  if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''}`;
  }

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  }

  if (minutes > 0) {
    return `${minutes} min${minutes > 1 ? 's' : ''}`;
  }

  return seconds < 3 ? 'right now' : `${seconds} sec`;
};

export const isAddressBookEqualData = (
  addressCurrent,
  addressBook,
  isEmailAdded = false,
) => {
  const currentPhone = isValidNumberForRegion(
    addressCurrent?.phone || '',
    addressCurrent?.country_code || '',
  )
    ? parsePhoneNumber(
        addressCurrent?.phone || '',
        addressCurrent?.country_code as CountryCode,
      )?.number
    : '';

  const addressCurrentFillData = {
    name: addressCurrent.name,
    phone: currentPhone,
    address_1: addressCurrent.address_1,
    address_2: addressCurrent.address_2,
    country: addressCurrent.country,
    city: addressCurrent.city,
    postcode: addressCurrent.postcode,
    ...(isEmailAdded && {
      email: addressCurrent?.email || '',
    }),
  };

  const addressBookFillData = {
    name: addressBook.name,
    phone: addressBook?.phone,
    address_1: addressBook.address_1,
    address_2: addressBook?.address_2 || '',
    country: addressBook.country,
    city: addressBook.city,
    postcode: addressBook.postcode,
    ...(isEmailAdded && {
      email: addressBook?.email || '',
    }),
  };
  return isEqual(addressCurrentFillData, addressBookFillData);
};

export const parsePhoneToNationalNumber = (p: string): string =>
  p
    ? parsePhoneNumber(p, getCountryCodeFromPhone(p) || 'CA')?.nationalNumber
    : '';

export const getCountryCodePhone = (phone: string) => {
  if (!phone) return 'CA';
  if (phone?.startsWith('+1')) {
    try {
      const country = parsePhoneNumber(phone, 'CA')?.country || 'CA';
      return country;
    } catch (error) {
      return 'CA';
    }
  }
  if (phone?.startsWith('+84')) return 'VN';
  if (phone?.startsWith('+61')) return 'AU';
  return '';
};

export const convertEditTaskData = (
  task: Task,
  timezone?: string,
): IAddTaskData => {
  const {
    pickup,
    delivery,
    expected_delivery_after,
    expected_delivery_before,
    require_pickup_proofs,
    require_delivery_capture,
    require_delivery_signature,
    skip_pickup,
    note,
    id,
  } = task;
  const newTask = {
    id,
    skip_pickup: !!skip_pickup,
    note,
    require_pickup_proofs: !!require_pickup_proofs,
    require_delivery_capture: !!require_delivery_capture,
    require_delivery_signature: !!require_delivery_signature,
    pickup: {} as ITaskAddress,
    delivery: {},
    expected_delivery_after: null,
    expected_delivery_before: null,
    expected_delivery_date: null,
  };

  const convertTimeToLocalTime = (t) => (t ? moment.utc(t).tz(timezone) : null);

  if (pickup) {
    newTask.pickup = {
      ...pickup,
      address_book: {},
      save_to_address_book: false,
    };
    newTask.pickup.country_code = getCountryCodePhone(pickup.phone);

    if (
      pickup.phone &&
      isValidNumberForRegion(
        pickup.phone,
        newTask.pickup?.country_code?.toUpperCase() as CountryCode,
      )
    )
      newTask.pickup.phone = parsePhoneToNationalNumber(pickup.phone);
    else
      newTask.pickup.phone = pickup.phone
        ?.replace(/\+1|\+84|\+61/g, '')
        ?.trim();
  } else {
    newTask.pickup = {
      country_code: 'CA',
      address_book: {},
    };
  }

  // convert utc ->  local time
  newTask.expected_delivery_after = convertTimeToLocalTime(
    expected_delivery_after,
  );

  newTask.expected_delivery_before = convertTimeToLocalTime(
    expected_delivery_before,
  );

  newTask.expected_delivery_date = expected_delivery_before
    ? moment.utc(expected_delivery_before).tz(timezone).format('MM/DD/YYYY')
    : null;

  newTask.delivery = {
    ...delivery,
    address_book: {},
    phone: parsePhoneToNationalNumber(delivery.phone),
    country_code: getCountryCodeFromPhone(delivery.phone),
    save_to_address_book: false,
  };
  return newTask;
};

export const setDateToTimeSelector = (date: string, time: any) => {
  if (!date || !time) return null;
  const newDate = moment(date, 'MM/DD/YYYY'); // the new date
  time.set({
    year: newDate.year(),
    month: newDate.month(),
    date: newDate.date(),
  });
  return time.utc().format('YYYY-MM-DDTHH:mm:ss');
};

export const convertSubmitTaskData = (
  values: IAddTaskData,
  // isEditMode?: boolean,
) => {
  const newValues = cloneDeep(values);

  if (!newValues.skip_pickup) {
    newValues.pickup.phone = getPhoneWithCountryCode(
      newValues.pickup.phone,
      newValues.pickup?.country_code as CountryCode,
    );
  } else {
    newValues.pickup = null;
  }

  newValues.expected_delivery_after = setDateToTimeSelector(
    newValues.expected_delivery_date,
    newValues.expected_delivery_after,
  );

  if (
    newValues.expected_delivery_date &&
    !newValues.expected_delivery_before &&
    !newValues.expected_delivery_after
  ) {
    newValues.expected_delivery_before = setDateToTimeSelector(
      newValues.expected_delivery_date,
      moment().endOf('day'),
    );
  } else {
    newValues.expected_delivery_before = setDateToTimeSelector(
      newValues.expected_delivery_date,
      newValues.expected_delivery_before,
    );
  }

  // when task created from woo or
  // breadstack expected_delivery_after will be null
  //= > skip edit delivery time
  // if (
  //   isEditMode &&
  //   !newValues.expected_delivery_after &&
  //   newValues.expected_delivery_before
  // ) {
  //   delete newValues.expected_delivery_before;
  // }

  newValues.delivery.phone = parsePhoneNumber(
    newValues.delivery.phone,
    newValues.delivery.country_code as CountryCode,
  ).number;
  return omitBy(newValues, isNull);
};

export const validatePhoneNumber = (value: any, testContext: any): boolean => {
  if (value && testContext?.parent?.country_code) {
    return isValidNumberForRegion(
      value,
      testContext.parent.country_code.toUpperCase(),
    );
  }
  return false;
};

export const validateNumber = (value: any, testContext: any): boolean => {
  if (!testContext?.parent?.country_code && !value?.trim()?.startsWith('+'))
    return false;
  if (value) {
    return /^\+?[0-9 ()]+$/.test(value);
  }
  return true;
};

export const getAddressBookList = (values: IAddTaskData, countries) => {
  const newValues = cloneDeep(values);

  let addressBookSaveList = [];

  if (newValues?.pickup?.save_to_address_book && !values.skip_pickup) {
    const pickup = {
      ...newValues.pickup,
      phone: getPhoneWithCountryCode(
        newValues.pickup.phone,
        newValues.pickup?.country_code as CountryCode,
      ),
      state_name: getStateNameByStateCodeAndCountry(
        countries,
        newValues.pickup.country,
        newValues.pickup.state,
      ),
    };
    addressBookSaveList = [pickup];
  }

  if (newValues.delivery.save_to_address_book) {
    const delivery = {
      ...newValues.delivery,
      phone: parsePhoneNumber(
        newValues.delivery.phone,
        newValues.delivery.country_code as CountryCode,
      ).number,
      state_name: getStateNameByStateCodeAndCountry(
        countries,
        newValues.delivery.country,
        newValues.delivery.state,
      ),
    };

    addressBookSaveList = [...addressBookSaveList, delivery];
  }
  return addressBookSaveList;
};
