import { Divider, Menu, Stack, Typography } from '@mui/material';
import themes, { styled } from '~/themes';
import StoreIcon from '~/assets/images/map/store-filter.svg';
import DriverIcon from '~/assets/images/map/car-filter.svg';
import TrafficLightIcon from '~/assets/images/map/traffic-light-filter.svg';
import UnassignedIcon from '~/assets/images/map/dropoff-not-assigned.svg';
import PendingIcon from '~/assets/images/map/dropoff-filter.svg';
import InprogressIcon from '~/assets/images/map/dropoff-inprogress-filter.svg';
import MapIcon from '~/assets/images/map/map.svg';
import { useCallback, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Close } from '@mui/icons-material';
import { MarkerType } from '../types';
import { useLiveMapContext } from '../hooks/useLiveMapContext';

type ViewControlProps = {};

export const StyledViewControlIcon = styled('img')(({ theme }) => ({
  width: theme.spacing(1.6),
  height: theme.spacing(1.6),
}));

export const StyledControlItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'notAllow',
})<{ active: boolean; notAllow?: boolean }>(
  ({ theme, active, notAllow = false }) => ({
    'display': 'flex',
    'alignItems': 'center',
    'gap': theme.spacing(0.8),
    'padding': theme.spacing(0.8),
    'borderRadius': '8px',
    'fontSize': '12px',
    'color': '#33363D',
    'cursor': 'pointer',
    'border': '1px solid #E2E7F0',
    '& img': {
      width: theme.spacing(1.6),
      height: theme.spacing(1.6),
    },
    ...(active && {
      borderColor: '#121142',
      background: '#F4F5F9',
    }),
    ...(notAllow && {
      cursor: 'not-allowed',
    }),
  }),
);

export const ViewControl: React.FC<ViewControlProps> = () => {
  const {
    isOpenTaskDetail,
    isOpenManageRoutes,
    viewControls,
    expandedLiveMonitoring,
    setViewControls,
    showMarkers,
    hideMarkers,
  } = useLiveMapContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewControl = useCallback(
    (val: MarkerType) => {
      const isOn = viewControls.includes(val);
      const updatedViewControls = isOn
        ? viewControls.filter((e) => e !== val)
        : viewControls.concat([val]);

      const markerOptions = {
        taskDetail: isOpenTaskDetail,
        default: !isOpenTaskDetail && !isOpenManageRoutes,
        manageRoute: isOpenManageRoutes,
      };

      if (val === MarkerType.Traffic) {
        setViewControls(updatedViewControls);
        return;
      }

      if (isOn) {
        hideMarkers([val], markerOptions);
      } else {
        showMarkers([val], markerOptions);
      }

      setViewControls(updatedViewControls);
    },
    [viewControls, isOpenTaskDetail, isOpenManageRoutes],
  );

  const ViewControlOptions = [
    {
      icon: StoreIcon,
      value: MarkerType.Store,
      label: 'Pickup location',
    },
    {
      icon: DriverIcon,
      value: MarkerType.Driver,
      label: 'Driver',
    },
    {
      icon: TrafficLightIcon,
      value: MarkerType.Traffic,
      label: 'Traffic',
    },
  ];

  const TaskControlOptions = [
    {
      icon: UnassignedIcon,
      value: MarkerType.Unassigned,
      label: 'Task not assigned',
    },
    {
      icon: PendingIcon,
      value: MarkerType.Pending,
      label: 'Task pending pickup',
    },
    {
      icon: InprogressIcon,
      value: MarkerType.Inprogress,
      label: 'Task in progress',
    },
  ];

  const menuFilterComponent = () => (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        sx: {
          padding: 0,
        },
      }}
      slotProps={{
        paper: {
          sx: {
            width: 180,
            borderRadius: '5px',
            p: 1.6,
          },
        },
      }}
    >
      <Stack flexDirection='column' gap={0.8}>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h4'>Map Filters</Typography>
          <Close
            sx={{
              color: themes.color.violet900,
              width: 15,
              height: 15,
              cursor: 'pointer',
            }}
            onClick={handleClose}
          />
        </Stack>
        {ViewControlOptions?.map((item) => (
          <StyledControlItem
            key={item.value}
            active={viewControls.includes(item.value)}
            onClick={() => handleViewControl(item.value)}
          >
            <img src={item.icon} alt='' />
            <span>{item.label}</span>
          </StyledControlItem>
        ))}
      </Stack>
      <Divider sx={{ my: 0.8 }} />
      <Stack flexDirection='column' gap={0.8}>
        <Typography variant='caption'>Delivery location</Typography>
        {TaskControlOptions?.map((item) => (
          <StyledControlItem
            key={item.value}
            notAllow={isOpenTaskDetail || isOpenManageRoutes}
            active={viewControls.includes(item.value)}
            onClick={() => {
              if (!isOpenTaskDetail && !isOpenManageRoutes) {
                handleViewControl(item.value);
              }
            }}
          >
            <img src={item.icon} alt='' />
            <span>{item.label}</span>
          </StyledControlItem>
        ))}
      </Stack>
    </Menu>
  );

  if (isMobile && !isTablet && expandedLiveMonitoring) {
    return <div />;
  }

  return (
    <>
      <Stack
        sx={{
          padding: themes.spacing(0.8),
          position: 'absolute',
          right: themes.spacing(1.6),
          bottom: themes.spacing(10.2),
          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
          borderRadius: '5px',
          background: themes.bg.white,
          zIndex: themes.zIndex.drawer + 1,
          [themes.breakpoints.down('md')]: {
            bottom: themes.spacing(2),
          },
        }}
        onClick={handleClick}
      >
        <StyledViewControlIcon src={MapIcon} alt='filter-icon' />
      </Stack>
      {menuFilterComponent()}
    </>
  );
};
