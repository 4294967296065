import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useMemo } from 'react';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import Modal from '~/components/common/Modal';
import { TextInput } from '~/components/common/TextInput';
import { TIME_SLOT_TYPE, weekdays } from '~/constants/stores';
import {
  DeliveryHours,
  IStore,
  ZonesType,
  TimeSlotsType,
} from '~/models/stores';
import { usePreviewTimeSlot } from '~/services/api/stores';
import themes from '~/themes';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

type DialogIndividualTimeSlotsProps = {
  open?: boolean;
  stores?: IStore;
  onClose: () => void;
  onSaveDataSetLimit: (values: DeliveryHours) => void;
};

export const DialogIndividualTimeSlots: React.FC<
  DialogIndividualTimeSlotsProps
> = ({ open, stores, onClose, onSaveDataSetLimit }) => {
  const { data: deliveryData, isFetching: isFetchingPreviewTimeSlot } =
    usePreviewTimeSlot({
      params: stores,
    });

  const deliveryHoursPreview = deliveryData?.delivery_hours;

  const convertDeliveryHours = (dHours) => {
    if (!dHours) return {};
    const newData = {};
    const weekDays = Object.keys(dHours);
    weekDays.forEach((wd) => {
      const hours = dHours[wd];
      const { time_slot_type = TimeSlotsType.LimitAllSlot } = hours;
      newData[wd] = { ...hours, time_slot_type };
    });
    return newData;
  };

  const getTimeSlots = (dHours, weekday) => {
    if (!dHours) return [];
    const { time_slot } = dHours[weekday];
    return dHours[weekday]
      ? time_slot.flat().map((slot) => ({
          label: `${moment(slot.from_time, 'HH:mm').format(
            'hh:mm a',
          )} - ${moment(slot.to_time, 'HH:mm').format('hh:mm a')}`,
          value: `${weekday}@${slot.from_time} - ${slot.to_time}`,
          limit: slot.limit || null,
        }))
      : [];
  };

  const getTimeSlotSelected = (dHours, weekday) =>
    getTimeSlots(dHours, weekday)[0]?.value || '';

  const getTimeSlotZones = (dHours, weekday, timeSlotSelected) => {
    if (!dHours) return [];
    const { time_slot } = dHours[weekday];
    const zones =
      time_slot?.find(
        (slot) =>
          `${weekday}@${slot.from_time} - ${slot.to_time}` === timeSlotSelected,
      )?.limit_zones || [];
    return zones;
  };

  const updateDeliveryHoursField = (
    setFieldValue,
    values,
    updateValues: {
      allLimit?: number;
      updateTimeSlotKey?: string;
      timeSlotLimit?: number;
      time_slot_type?: TimeSlotsType;
      zone_type?: ZonesType;
      zoneId?: string;
    },
  ) => {
    const {
      allLimit,
      updateTimeSlotKey,
      timeSlotLimit,
      time_slot_type,
      zone_type,
      zoneId,
    } = updateValues;

    const { weekday, deliveryHours } = values;

    const newVal = {
      ...deliveryHours,
      [weekday]: {
        ...deliveryHours[weekday],
        ...(time_slot_type && {
          time_slot_type,
        }),
        ...(zone_type &&
          zone_type === ZonesType.LimitAllZones &&
          deliveryHours[weekday].time_slot_type ===
            TimeSlotsType.LimitAllSlot && {
            limit_all_zone: true,
          }),
        ...(zone_type &&
          zone_type === ZonesType.LimitIndividualZones && {
            limit_all_zone: false,
          }),
        // for all time slot all zone
        ...(time_slot_type === TimeSlotsType.LimitAllSlot &&
          zone_type === ZonesType.LimitAllZones && {
            limit: allLimit || null,
          }),
        // for all time time each zone
        ...(time_slot_type === TimeSlotsType.LimitAllSlot &&
          zone_type === ZonesType.LimitIndividualZones &&
          zoneId && {
            limit_zones: deliveryHours[values.weekday]?.limit_zones?.map((zn) =>
              zn.zone_id === zoneId
                ? { ...zn, limit: timeSlotLimit || null }
                : zn,
            ),
          }),
        ...(updateTimeSlotKey && {
          time_slot: deliveryHours[weekday]?.time_slot?.length
            ? deliveryHours[weekday].time_slot.map((slot) =>
                `${weekday}@${slot.from_time} - ${slot.to_time}` ===
                updateTimeSlotKey
                  ? {
                      ...slot,
                      ...(time_slot_type ===
                        TimeSlotsType.LimitIndividualSlot &&
                        zone_type === ZonesType.LimitAllZones && {
                          limit: timeSlotLimit || null,
                        }),
                      ...(deliveryHours[weekday].time_slot_type ===
                        TimeSlotsType.LimitIndividualSlot && {
                        limit_all_zone: zone_type === ZonesType.LimitAllZones,
                      }),
                      ...(time_slot_type ===
                        TimeSlotsType.LimitIndividualSlot &&
                        zone_type === ZonesType.LimitIndividualZones && {
                          limit_zones: slot?.limit_zones?.map((zn) =>
                            zn.zone_id === zoneId
                              ? { ...zn, limit: timeSlotLimit || null }
                              : zn,
                          ),
                        }),
                    }
                  : slot,
              )
            : {},
        }),
      },
    };
    setFieldValue('deliveryHours', newVal);
  };

  const handleSubmit = (values) => {
    const { deliveryHours } = values;
    const params = Object.keys(deliveryHours).reduce((acc, key) => {
      const hours = deliveryHours[key];
      const { time_slot_type, limit_all_zone } = hours;

      if (time_slot_type === TimeSlotsType.LimitAllSlot) {
        if (limit_all_zone) {
          acc[key] = {
            ...hours,
            limit_zones: hours?.limit_zones?.map((zn) => ({
              ...zn,
              limit: null,
            })),
            ...(hours?.time_slot && {
              time_slot: hours.time_slot.map((slot) => ({
                ...slot,
                limit: null,
                limit_all_zone: false,
                limit_zones: slot?.limit_zones?.map((zn) => ({
                  ...zn,
                  limit: null,
                })),
              })),
            }),
          };
        } else {
          acc[key] = {
            ...hours,
            limit: null,
            ...(hours?.time_slot && {
              time_slot: hours.time_slot.map((slot) => ({
                ...slot,
                limit: null,
                limit_all_zone: false,
                limit_zones: slot?.limit_zones?.map((zn) => ({
                  ...zn,
                  limit: null,
                })),
              })),
            }),
          };
        }
      }
      if (time_slot_type === TimeSlotsType.LimitIndividualSlot) {
        acc[key] = {
          ...hours,
          limit: null,
          limit_all_zone: false,
          limit_zones: hours.limit_zones?.map((zn) => ({
            ...zn,
            limit: null,
          })),
          ...(hours?.time_slot && {
            time_slot: hours.time_slot.map((slot) => ({
              ...slot,
              limit: slot.limit_all_zone ? slot.limit : null,
              limit_zones: slot?.limit_zones,
              ...(slot?.limit_all_zone && {
                limit_zones: slot?.limit_zones?.map((zn) => ({
                  ...zn,
                  limit: null,
                })),
              }),
            })),
          }),
        };
      }

      // for test
      if (!time_slot_type) {
        acc[key] = { ...hours };
      }

      return acc;
    }, {});

    onSaveDataSetLimit(params);
    onClose();
  };

  const weekDaysComponent = (values, setFieldValue) => (
    <Stack direction='column'>
      <Stack
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          height: '40px',
        }}
      >
        <Typography
          sx={{
            color: '#8C95BA',
          }}
          variant='caption'
        >
          Day of the week
        </Typography>
      </Stack>
      <Stack direction='column' spacing={0.5} mt={0.5}>
        {weekdays.map(({ label, value }) => (
          <Stack
            key={label}
            sx={{
              'lineHeight': '20px',
              'color': '#33363D',
              'borderRadius': '4px',
              'padding': '8px',
              'cursor': 'pointer',
              '&:hover': { background: '#E5E3F4' },
              ...(value === values.weekday && {
                background: '#E5E3F4',
              }),
            }}
            onClick={() => {
              setFieldValue('weekday', value);
              const timeSlotSelected = getTimeSlotSelected(
                values.deliveryHours,
                value,
              );
              setFieldValue('timeSlotSelected', timeSlotSelected);
            }}
          >
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );

  const timeSlotsComponent = (values, setFieldValue) => {
    const { delivery_hours } = stores;
    const timeSlots = getTimeSlots(values.deliveryHours, values.weekday);
    const noTimeSlot =
      !timeSlots.length ||
      !delivery_hours[values.weekday]?.available ||
      delivery_hours[values.weekday]?.time_slot_generation ===
        TIME_SLOT_TYPE.NONE;

    if (noTimeSlot) {
      return (
        <Stack flexDirection='column'>
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              height: '40px',
            }}
          >
            <Typography
              sx={{
                color: '#8C95BA',
              }}
              variant='caption'
            >
              Time slots
            </Typography>
            <ElementSelect
              paperProps={{
                sx: {
                  width: 160,
                },
              }}
              elementSelect={() => <MoreHorizIcon sx={{ color: '#33363D' }} />}
              options={[
                {
                  label: 'All Time Slots',
                  value: TimeSlotsType.LimitAllSlot,
                },
                {
                  label: 'Each Time Slot',
                  value: TimeSlotsType.LimitIndividualSlot,
                },
              ]}
              onChange={() => {}}
            />
          </Stack>
          <Stack maxHeight={500} mt={0.5} spacing={0.5}>
            <Typography
              sx={{
                lineHeight: '20px',
                color: '#33363D',
                borderRadius: '4px',
                padding: '8px',
                cursor: 'pointer',
                background: '#E5E3F4',
              }}
            >
              No time slot
            </Typography>
          </Stack>
        </Stack>
      );
    }

    const individualTimeSlotsType =
      values.deliveryHours[values.weekday]?.time_slot_type ||
      TimeSlotsType.LimitAllSlot;

    const { timeSlotSelected } = values;

    return (
      <Stack>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            height: '40px',
          }}
        >
          <Typography
            sx={{
              color: '#8C95BA',
            }}
            variant='caption'
          >
            Time slots
          </Typography>
          <ElementSelect
            paperProps={{
              sx: {
                width: 160,
              },
            }}
            elementSelect={() => <MoreHorizIcon sx={{ color: '#33363D' }} />}
            value={individualTimeSlotsType}
            onChange={(o) => {
              updateDeliveryHoursField(setFieldValue, values, {
                time_slot_type: o.value,
              });
              const newTimeSlotSelected = getTimeSlotSelected(
                values.deliveryHours,
                values.weekday,
              );
              setFieldValue('timeSlotSelected', newTimeSlotSelected);
            }}
            options={[
              {
                label: 'All Time Slots',
                value: TimeSlotsType.LimitAllSlot,
              },
              {
                label: 'Each Time Slot',
                value: TimeSlotsType.LimitIndividualSlot,
              },
            ]}
          />
        </Stack>
        {individualTimeSlotsType === TimeSlotsType.LimitAllSlot && (
          <Stack maxHeight={500} mt={0.5} spacing={0.5}>
            <Typography
              sx={{
                lineHeight: '20px',
                color: '#33363D',
                borderRadius: '4px',
                padding: '8px',
                cursor: 'pointer',
                background: '#E5E3F4',
              }}
            >
              All time slots
            </Typography>
          </Stack>
        )}
        {individualTimeSlotsType === TimeSlotsType.LimitIndividualSlot && (
          <Stack
            maxHeight={500}
            mt={0.5}
            spacing={0.5}
            className='customized-scrollbar'
            sx={{
              overflowY: 'auto',
            }}
          >
            {timeSlots.map((opt) => (
              <Typography
                onClick={() => {
                  setFieldValue('timeSlotSelected', opt.value);
                }}
                sx={{
                  'lineHeight': '20px',
                  'color': '#33363D',
                  'borderRadius': '4px',
                  'padding': '8px',
                  'cursor': 'pointer',
                  '&:hover': { background: '#E5E3F4' },
                  ...(timeSlotSelected === opt.value && {
                    background: '#E5E3F4',
                  }),
                }}
                key={opt.value}
              >
                {opt.label}
              </Typography>
            ))}
          </Stack>
        )}
      </Stack>
    );
  };

  const orderLimitZoneComponent = (values, setFieldValue) => {
    const { delivery_hours } = stores;
    const { weekday, deliveryHours, timeSlotSelected } = values;
    const timeSlots = getTimeSlots(deliveryHours, weekday);
    const noTimeSlot =
      !timeSlots.length ||
      !delivery_hours[weekday]?.available ||
      delivery_hours[weekday]?.time_slot_generation === TIME_SLOT_TYPE.NONE;

    const noZone =
      delivery_hours[weekday]?.limit_zones?.length === 0 ||
      delivery_hours[weekday]?.time_slot?.[0]?.limit_zones?.length === 0;

    if (noTimeSlot || noZone) {
      return (
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Stack flexDirection='column'>
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  height: '40px',
                }}
              >
                <Typography
                  sx={{
                    color: '#8C95BA',
                  }}
                  variant='caption'
                >
                  Delivery zones
                </Typography>
                <ElementSelect
                  paperProps={{
                    sx: {
                      width: 160,
                    },
                  }}
                  elementSelect={() => (
                    <MoreHorizIcon sx={{ color: '#33363D' }} />
                  )}
                  options={[
                    {
                      label: 'All Delivery Zones',
                      value: ZonesType.LimitAllZones,
                    },
                    {
                      label: 'Each Delivery Zone',
                      value: ZonesType.LimitIndividualZones,
                    },
                  ]}
                  onChange={() => {}}
                />
              </Stack>
              <Stack maxHeight={500} mt={0.5} spacing={0.5}>
                <Typography
                  sx={{
                    lineHeight: '20px',
                    color: '#33363D',
                    borderRadius: '4px',
                    padding: '8px',
                    cursor: 'pointer',
                  }}
                >
                  All delivery zones
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack flexDirection='column'>
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  height: '40px',
                }}
              >
                <Typography
                  sx={{
                    color: '#8C95BA',
                  }}
                  variant='caption'
                >
                  Order Limit
                </Typography>
              </Stack>
              <TextInput
                type='number'
                value=''
                inputProps={{
                  autoFocus: true,
                  min: 1,
                  step: 'any',
                  readOnly: true,
                }}
                sx={{
                  mt: 0.55,
                  [themes.breakpoints.down('md')]: { width: '100%' },
                }}
                disabled
              />
            </Stack>
          </Grid>
        </Grid>
      );
    }

    const currentTimeSlot = deliveryHours[weekday]?.time_slot?.find(
      (slot) =>
        `${weekday}@${slot.from_time} - ${slot.to_time}` === timeSlotSelected,
    );

    const individualTimeSlotsType =
      deliveryHours[weekday]?.time_slot_type || TimeSlotsType.LimitAllSlot;

    let zoneType = deliveryHours[weekday]?.limit_all_zone
      ? ZonesType.LimitAllZones
      : ZonesType.LimitIndividualZones;

    if (individualTimeSlotsType === TimeSlotsType.LimitIndividualSlot) {
      zoneType = currentTimeSlot?.limit_all_zone
        ? ZonesType.LimitAllZones
        : ZonesType.LimitIndividualZones;
    }

    let zones = [];
    if (
      zoneType === ZonesType.LimitIndividualZones &&
      individualTimeSlotsType === TimeSlotsType.LimitAllSlot
    ) {
      zones =
        deliveryHours[weekday]?.limit_zones?.map((zn) => ({
          value: zn.zone_id,
          label: zn.zone_name,
        })) || [];
    }
    if (
      zoneType === ZonesType.LimitIndividualZones &&
      individualTimeSlotsType === TimeSlotsType.LimitIndividualSlot
    ) {
      zones =
        getTimeSlotZones(deliveryHours, weekday, timeSlotSelected)?.map(
          (zn) => ({
            value: zn.zone_id,
            label: zn.zone_name,
          }),
        ) || [];
    }

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              height: '40px',
            }}
          >
            <Typography
              sx={{
                color: '#8C95BA',
              }}
              variant='caption'
            >
              Delivery zones
            </Typography>
            <ElementSelect
              paperProps={{
                sx: {
                  width: 160,
                },
              }}
              elementSelect={() => <MoreHorizIcon sx={{ color: '#33363D' }} />}
              value={zoneType}
              onChange={(o) => {
                if (individualTimeSlotsType === TimeSlotsType.LimitAllSlot) {
                  updateDeliveryHoursField(setFieldValue, values, {
                    zone_type: o.value,
                  });
                } else {
                  updateDeliveryHoursField(setFieldValue, values, {
                    updateTimeSlotKey: timeSlotSelected,
                    zone_type: o.value,
                  });
                }
              }}
              options={[
                {
                  label: 'All Delivery Zones',
                  value: ZonesType.LimitAllZones,
                },
                {
                  label: 'Each Delivery Zone',
                  value: ZonesType.LimitIndividualZones,
                },
              ]}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              height: '40px',
            }}
          >
            <Typography
              sx={{
                color: '#8C95BA',
              }}
              variant='caption'
            >
              Order Limit
            </Typography>
          </Stack>
        </Grid>
        {zoneType === ZonesType.LimitAllZones && (
          <>
            <Grid item xs={6}>
              <Stack maxHeight={500} mt={0.5} spacing={0.5}>
                <Typography
                  sx={{
                    lineHeight: '20px',
                    color: '#33363D',
                    borderRadius: '4px',
                    padding: '8px',
                  }}
                >
                  All delivery zones
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                type='number'
                inputProps={{ autoFocus: true, min: 1, step: 'any' }}
                sx={{
                  mt: 0.55,
                  [themes.breakpoints.down('md')]: { width: '100%' },
                }}
                value={
                  individualTimeSlotsType === TimeSlotsType.LimitAllSlot
                    ? deliveryHours[weekday]?.limit || ''
                    : currentTimeSlot?.limit || ''
                }
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10);
                  if (individualTimeSlotsType === TimeSlotsType.LimitAllSlot) {
                    updateDeliveryHoursField(setFieldValue, values, {
                      allLimit: value || null,
                      zone_type: ZonesType.LimitAllZones,
                      time_slot_type: TimeSlotsType.LimitAllSlot,
                    });
                  } else {
                    updateDeliveryHoursField(setFieldValue, values, {
                      timeSlotLimit: value || null,
                      zone_type: ZonesType.LimitAllZones,
                      time_slot_type: TimeSlotsType.LimitIndividualSlot,
                      updateTimeSlotKey: timeSlotSelected,
                    });
                  }
                }}
              />
            </Grid>
          </>
        )}
        {zoneType === ZonesType.LimitIndividualZones && (
          <Grid item xs={12}>
            <Grid
              container
              columnSpacing={2}
              maxHeight={500}
              mt={0.5}
              spacing={0.5}
              className='customized-scrollbar'
              sx={{
                overflowY: 'auto',
              }}
            >
              {zones?.map((zone, index) => (
                <React.Fragment key={zone.value}>
                  <Grid item xs={6}>
                    <Stack
                      maxHeight={500}
                      mt={0.5}
                      spacing={0.5}
                      className='customized-scrollbar'
                      sx={{
                        overflowY: 'auto',
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: '20px',
                          color: '#33363D',
                          borderRadius: '4px',
                          padding: '8px',
                          cursor: 'pointer',
                        }}
                      >
                        {zone.label}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      type='number'
                      inputProps={{
                        autoFocus: index === 0,
                        min: 1,
                        step: 'any',
                      }}
                      sx={{
                        mt: 0.55,
                        [themes.breakpoints.down('md')]: { width: '100%' },
                      }}
                      value={
                        individualTimeSlotsType === TimeSlotsType.LimitAllSlot
                          ? deliveryHours[weekday]?.limit_zones?.find(
                              (zn) => zn.zone_id === zone.value,
                            )?.limit || ''
                          : currentTimeSlot?.limit_zones?.find(
                              (zn) => zn.zone_id === zone.value,
                            )?.limit || ''
                      }
                      onChange={(event) => {
                        const value = parseInt(event.target.value, 10);
                        if (
                          individualTimeSlotsType === TimeSlotsType.LimitAllSlot
                        ) {
                          updateDeliveryHoursField(setFieldValue, values, {
                            timeSlotLimit: value || null,
                            zone_type: ZonesType.LimitIndividualZones,
                            time_slot_type: TimeSlotsType.LimitAllSlot,
                            zoneId: zone.value,
                          });
                        } else {
                          updateDeliveryHoursField(setFieldValue, values, {
                            timeSlotLimit: value || null,
                            zone_type: ZonesType.LimitIndividualZones,
                            time_slot_type: TimeSlotsType.LimitIndividualSlot,
                            updateTimeSlotKey: timeSlotSelected,
                            zoneId: zone.value,
                          });
                        }
                      }}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const loadingComponent = useMemo(
    () => (
      <Stack alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    ),
    [],
  );

  const limitSlotForm = () => {
    if (!deliveryHoursPreview)
      return <Typography>No delivery hours data.</Typography>;
    return (
      <Formik
        initialValues={{
          weekday: 'sunday',
          deliveryHours: convertDeliveryHours(deliveryHoursPreview),
          timeSlotSelected: getTimeSlotSelected(deliveryHoursPreview, 'sunday'),
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form id='individual_time_slots'>
            <Grid container columnSpacing={2}>
              <Grid item xs={3}>
                {weekDaysComponent(values, setFieldValue)}
              </Grid>
              <Grid item xs={3}>
                {timeSlotsComponent(values, setFieldValue)}
              </Grid>
              <Grid item xs={6}>
                {orderLimitZoneComponent(values, setFieldValue)}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  };
  return (
    <Modal
      onClose={onClose}
      open={open}
      title='Edit Order Limit'
      disableCloseOutside
      PaperProps={{
        sx: {
          'maxWidth': 800,
          '& .MuiDialogContent-root': {
            overflow: 'hidden',
          },
        },
      }}
      actions={[
        {
          title: 'Cancel',
          onClick: () => onClose(),
          buttonType: 'default',
        },
        {
          title: 'Save',
          buttonType: 'primary',
          type: 'submit',
          form: 'individual_time_slots',
          id: 'individual_time_slots',
          loading: false,
          disabled: !deliveryHoursPreview,
        },
      ]}
    >
      {isFetchingPreviewTimeSlot ? loadingComponent : limitSlotForm()}
    </Modal>
  );
};
