import { Avatar, Badge } from '@mui/material';
import { DriverStatus } from '~/pages/LiveTracking/types';
import themes from '~/themes';

type Props = {
  status?: string;
  avatar: string;
  size?: number;
  name?: string;
};

export const stringToAvatar = (s: string) => {
  const trimmedString = s.trim();
  return trimmedString[0]?.toUpperCase() || 'D';
};

export const genAvatarSrc = (avatar: string) => {
  if (!avatar) return '';
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  const isBase64Avatar = base64regex.test(avatar);
  return isBase64Avatar ? `data:image/png;base64, ${avatar}` : avatar;
};

export const DriverAvatar = (props: Props) => {
  const { status, avatar, size = 30, name } = props;
  const isOnlineDriver = status === DriverStatus.Online;
  const isIdleDriver = status === DriverStatus.Idle;
  const isOfflineDriver = status === DriverStatus.Offline;
  const isHiddenBadge = !status;
  const avatarSrc = genAvatarSrc(avatar);
  const avatarComponent = (
    <Avatar
      src={avatarSrc}
      alt='driver-avatar'
      sx={{ width: size, height: size }}
      {...(!avatar &&
        name?.trim() && {
          children: stringToAvatar(name),
          sx: {
            width: size,
            height: size,
            backgroundColor: '#bdbdbd',
            fontWeight: 500,
            ...(size < 30 && {
              fontSize: size / 1.5,
            }),
          },
        })}
    />
  );

  if (isHiddenBadge) {
    return avatarComponent;
  }

  return (
    <Badge
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: themes.color.gray600,
          color: themes.color.gray600,
          border: '1px solid white',
          height: 12,
          width: 12,
          borderRadius: '50%',
          ...(isOnlineDriver && {
            backgroundColor: themes.color.green300,
          }),
          ...(isIdleDriver && {
            backgroundColor: themes.color.orange,
          }),
          ...(isOfflineDriver && {
            backgroundColor: themes.color.gray500,
          }),
        },
      }}
      overlap='circular'
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant='dot'
    >
      {avatarComponent}
    </Badge>
  );
};
