// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from '~/config';

export const initializeSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    denyUrls: [/maps\.googleapis\.com/],
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration({
      //   maskAllText: true,
      //   blockAllMedia: true,
      // }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
