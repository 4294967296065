import { Stack, Typography } from '@mui/material';
import { useMemo, useState, memo, useEffect } from 'react';
import themes from '~/themes';
import { cleanDeep } from '~/utils/queryString';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { useFirstLoadingDetection } from '~/hooks';
import { isDesktop } from 'react-device-detect';
import { UserStatus } from '~/pages/AdminOptions/Users/types';
import { get, LOCAL_STORAGE_KEYS } from '~/utils/localStorage';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { StyledContainer } from '../../style';
import {
  useGetDrivers,
  useGetStoreLocations,
  useGetLiveTrackingTasks,
} from '../../apis';
import {
  DriverStatus,
  Driver as IDriver,
  Task as ITask,
  LocationInfo,
  MarkerType,
  TaskStatus,
} from '../../types';
import { TaskList } from './components/TaskList';
import { useLiveMapContext } from '../../hooks/useLiveMapContext';
import { DriverList } from './components/DriverList';
import { Filters } from './components/Filters';
import { DialogAddTask } from '../AddTask';
import useCheckRefreshTask from '../../hooks/useCheckRefreshTask';

type LiveMonitoringProps = {};

enum LiveMonitoringTab {
  Task = 'task',
  Driver = 'driver',
}

export const LiveMonitoring: React.FC<LiveMonitoringProps> = memo(() => {
  const isSaveTask = useFeatureIsOn('cf_1080_save_task_form');
  const { org, setIsOpenUpSellDialog, isStarterPlan } = usePaymentInfo();
  const {
    map,
    taskId,
    driverId,
    taskRoutes,
    driverRoutes,
    isOpenTaskDetail,
    isOpenManageRoutes,
    viewControls,
    expandedLiveMonitoring,
    mapBoundsPadding,
    setExpandedLiveMonitoring,
    handleMapFitBounds,
    addMarkerWithTasks,
    addMarkerWithDrivers,
    addMarkerWithStoreLocations,
    handleCloseTaskDetail,
    handleCloseManageRoutes,
  } = useLiveMapContext();

  const {
    hasUpdate,
    resetUpdate,
    hasConnected,
    forceUpdate: forceUpdateTask,
  } = useCheckRefreshTask();

  const [tab, setTab] = useState<LiveMonitoringTab>(null);
  const hasIncompletedTask =
    isSaveTask && !!get(LOCAL_STORAGE_KEYS.SAVE_TASK_FORM);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] =
    useState(hasIncompletedTask);

  const [filterParams, setFilterParams] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const {
    data: taskResponseData,
    isFetching: isFetchingTasks,
    isPreviousData: isPreviousDataTasks,
  } = useGetLiveTrackingTasks({
    params: {
      statuses: [
        TaskStatus.Unassigned,
        TaskStatus.PendingPickup,
        TaskStatus.Delivering,
        TaskStatus.Returning,
      ],
      ...filterParams,
      search: searchQuery,
    },
    refetchInterval:
      !isOpenManageRoutes && !openCreateTaskDialog ? 10 * 1000 : false,
    enabled: hasUpdate || !hasConnected,
    onSuccess: (resp: { items: ITask[] }) => {
      resetUpdate();
      addMarkerWithTasks(
        resp.items.filter((t) => !t.isDivider),
        {
          isAddMarkerToCluster:
            !isOpenTaskDetail &&
            !isOpenManageRoutes &&
            viewControls.includes(MarkerType.DropOff),
        },
      );
    },
  });

  const taskData = taskResponseData || {
    items: [],
    total_items: 0,
  };

  const { data: driverData, isFetching: isFetchingDrivers } = useGetDrivers({
    params: {
      roles: 'driver',
      expand: 'live',
      status: UserStatus.Active,
    },
    refetchInterval:
      !isOpenManageRoutes && !isOpenTaskDetail ? 10 * 1000 : false,
    onSuccess: (resp: IDriver[]) => {
      const driversWithoutOffline = resp?.filter(
        (driver) => driver?.driver_status !== DriverStatus.Offline,
      );
      addMarkerWithDrivers(driversWithoutOffline, {
        isAddMarkerToCluster:
          !isOpenTaskDetail &&
          !isOpenManageRoutes &&
          viewControls.includes(MarkerType.Driver),
      });
    },
  });

  const { data: storeLocations } = useGetStoreLocations({
    onSuccess: (locations: LocationInfo[]) => {
      addMarkerWithStoreLocations(locations);
    },
  });

  const isShowTaskListLoading = !(!isFetchingTasks || !isPreviousDataTasks);
  const isFirstLoadingTaskList = useFirstLoadingDetection([isFetchingTasks]);
  const isFirstLoadingDriverList = useFirstLoadingDetection([
    isFetchingDrivers,
  ]);

  const task = useMemo(
    () => taskData.items.find((t) => t.id === taskId) || {},
    [taskData, taskId],
  );
  const driver = useMemo(
    () => driverData.find((t) => t.id === driverId) || {},
    [driverData, driverId],
  );

  useEffect(() => {
    forceUpdateTask();
  }, [filterParams, searchQuery]);

  useEffect(() => {
    const tabVal = isOpenTaskDetail
      ? LiveMonitoringTab.Task
      : LiveMonitoringTab.Driver;
    if (isOpenTaskDetail || isOpenManageRoutes) {
      setExpandedLiveMonitoring(true);
      setTab(tabVal);
    }
  }, [isOpenTaskDetail, isOpenManageRoutes]);

  useEffect(() => {
    if (map && task?.id && taskRoutes) {
      const { pickup, delivery, executor } = task;
      const storePos = pickup ? { lat: pickup.lat, lng: pickup.lng } : null;
      const dropOffPos = delivery
        ? { lat: delivery.lat, lng: delivery.lng }
        : null;

      const driverPos = executor?.lat
        ? { lat: executor.lat, lng: executor.lng }
        : null;
      taskRoutes.hideMarkers([MarkerType.DropOff, MarkerType.Store]);
      taskRoutes.addDropOffMarker(dropOffPos);
      taskRoutes.addStoreMarker(storePos);
      taskRoutes.clearRoute();

      const isDuplicatePosition =
        storePos?.lat === dropOffPos?.lat && storePos?.lng === dropOffPos?.lng;
      if (storePos && dropOffPos && !isDuplicatePosition) {
        taskRoutes.drawRoute(storePos, dropOffPos);
      }

      handleMapFitBounds([storePos, dropOffPos, driverPos], {
        padding: mapBoundsPadding.TASK_ROUTES,
      });
    }
  }, [task, map, taskRoutes, isDesktop, mapBoundsPadding]);

  useEffect(() => {
    if (taskId && !task.id && !isFetchingTasks) {
      handleCloseTaskDetail();
    }
  }, [task?.id, taskId, isFetchingTasks]);

  useEffect(() => {
    if (driver?.id) {
      driverRoutes?.clearDriverInfoWindow();
      driverRoutes?.clearDriverRoutesMarkers();
      driverRoutes?.clearRoutes();
      driverRoutes?.setDriverSelected(driver);
    }
  }, [driver]);

  const liveMonitoringTabs = useMemo(() => {
    const tabs = [
      {
        value: LiveMonitoringTab.Task,
        icon: 'library-books',
        label: `${taskData.total_items} ${
          taskData.total_items > 1 ? 'Tasks' : 'Task'
        }`,
        hasRedDot: taskData.items.some(
          ({ status }) => status === TaskStatus.Unassigned,
        ),
      },
      {
        value: LiveMonitoringTab.Driver,
        label: `${driverData.length} ${
          driverData.length > 1 ? 'Drivers' : 'Driver'
        }`,
        icon: 'car',
        hasRedDot: false,
      },
    ];
    return tabs;
  }, [taskData, driverData]);

  const titleComponent = useMemo(
    () => (
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          variant='h4'
          sx={{
            color: '#33363D',
          }}
        >
          Live Monitoring
        </Typography>
        <Typography
          variant='body1'
          sx={{ cursor: 'pointer', color: '#33363D', fontWeight: 500 }}
          onClick={() => {
            if (isStarterPlan && org?.monthly_created_task >= org?.limit_task) {
              return setIsOpenUpSellDialog(true);
            }
            return setOpenCreateTaskDialog(true);
          }}
        >
          + Add Task
        </Typography>
      </Stack>
    ),
    [isStarterPlan, org],
  );

  const tabComponent = useMemo(
    () => (
      <Stack direction='row' spacing={1}>
        {liveMonitoringTabs.map(({ label, value, hasRedDot }) => {
          const isTabSelected = tab === value;
          return (
            <Stack
              key={value}
              spacing={1}
              direction='row'
              sx={{
                background: themes.bg.white,
                flex: 1,
                height: 32,
                borderRadius: '5px',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                ...(isTabSelected && {
                  background: themes.bg.darkPurple,
                  color: themes.color.white,
                }),
              }}
              onClick={() => {
                if (!expandedLiveMonitoring) {
                  setExpandedLiveMonitoring(true);
                }
                if (isTabSelected && expandedLiveMonitoring) {
                  setExpandedLiveMonitoring(false);
                }
                if (value === LiveMonitoringTab.Driver) {
                  handleCloseTaskDetail();
                }
                if (value === LiveMonitoringTab.Task) {
                  handleCloseManageRoutes();
                }
                setTab(isTabSelected ? null : value);
              }}
            >
              <Typography fontWeight={500}>{label}</Typography>
              {hasRedDot && !expandedLiveMonitoring && (
                <Typography
                  component='span'
                  sx={{
                    background: '#F00',
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                  }}
                />
              )}
            </Stack>
          );
        })}
      </Stack>
    ),
    [tab, liveMonitoringTabs, expandedLiveMonitoring],
  );

  const filterComponent = useMemo(() => {
    const filterParamsData = { ...filterParams };
    return (
      <Filters
        drivers={driverData || []}
        onApply={(filters) => {
          cleanDeep(filters);
          setFilterParams(filters);
        }}
        onClear={() => setFilterParams({})}
        onApplySearch={(search) => setSearchQuery(search)}
        onClearSearch={() => setSearchQuery('')}
        isFiltered={!!Object.keys(filterParamsData).length}
        isHidden={tab === LiveMonitoringTab.Driver}
        storeLocations={storeLocations}
      />
    );
  }, [driverData, filterParams, tab, storeLocations]);

  const tabContent = useMemo(() => {
    if (tab === LiveMonitoringTab.Task)
      return (
        <TaskList
          data={taskData.items}
          isLoading={isShowTaskListLoading}
          isFirstLoading={isFirstLoadingTaskList}
          isFiltered={!!Object.keys(filterParams).length || !!searchQuery}
        />
      );
    if (tab === LiveMonitoringTab.Driver)
      return (
        <DriverList
          data={driverData}
          isFirstLoading={isFirstLoadingDriverList}
        />
      );
    return '';
  }, [
    tab,
    driverData,
    taskData.items,
    isShowTaskListLoading,
    isFirstLoadingTaskList,
    isFirstLoadingDriverList,
    filterParams,
    searchQuery,
  ]);

  return (
    <StyledContainer
      expanded={expandedLiveMonitoring}
      openedDetail={Boolean(driverId) || Boolean(taskId)}
      spacing={2}
    >
      {titleComponent}
      {tabComponent}
      {filterComponent}
      {tabContent}
      {openCreateTaskDialog && (
        <DialogAddTask
          open={openCreateTaskDialog}
          onClose={() => setOpenCreateTaskDialog(false)}
        />
      )}
    </StyledContainer>
  );
});
