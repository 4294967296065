import { useLocation } from 'react-router-dom';
import { fromQueryString } from '~/utils/queryString';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import themes from '~/themes';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon } from '~/components/common/Icon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { alertParams, showAlert } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';
import Button from '~/components/common/Button';
import moment from 'moment';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useFirstLoadingDetection } from '~/hooks';
import ReturnIcon from '~/assets/images/map/return-purple.svg';
import DropOffIcon from '~/assets/images/map/dropoff-green.svg';
import PickUpIcon from '~/assets/images/map/pickup-orange.svg';
import { StyledTaskDetail } from '../../style';
import {
  LiveTrackingQueryKeys,
  useGetDriverDetail,
  useGetDriverRoutes,
  useOfflineDriverMutation,
  useOptimizeRoutes,
  useUpdateDriverRoutes,
} from '../../apis';
import { useLiveMapContext } from '../../hooks/useLiveMapContext';
import { DialogSendAlert } from '../DialogSendAlert';
import {
  convertDriverRoutesToRequestParams,
  groupDriverRoutes,
  isValidLatLngLiteral,
  sortDriverRoutes,
  updateDriverRoutes,
  updateOptimizedRoutesToDriverRoutes,
} from '../../utils';

import { OptimizeRouteLoading } from './OptimizeRouteLoading';
import {
  Driver,
  IDriverRoute,
  IDriverRouteResponse,
  MarkerType,
} from '../../types';
import { FirstLoading } from '../FirstLoading';

type ManageRoutesProps = {};

export const ManageRoutesWithoutDragDrop = forwardRef(
  (props: ManageRoutesProps, ref: React.Ref<any>) => {
    const location = useLocation();
    const queryParams = fromQueryString(location.search);
    const {
      driverRoutes,
      driverId,
      mapBoundsPadding,
      setViewControls,
      handleCloseManageRoutes,
      handleMapFitBounds,
    } = useLiveMapContext();
    const isShowOfflineDriver = useFeatureIsOn('cf-show-offline-driver');

    const [openSendAlert, setOpenSendAlert] = useState(false);
    const [isOnManageRoutes, setIsOnManageRoutes] = useState(false);
    const [reReRenderCount, setReRenderCount] = useState(0);
    const refetchGetDriverRoutesCountsRef = useRef(1);

    const routes = isOnManageRoutes
      ? driverRoutes.updateRoutes || []
      : driverRoutes?.routes || [];

    const offManageRoutes = useCallback(() => {
      setIsOnManageRoutes(false);
      setViewControls((v: MarkerType[]) => [
        ...Array.from(new Set(v.concat([MarkerType.DropOff]))),
      ]);
    }, []);

    const {
      data: driverRoutesResp,
      refetch: refetchGetDriverRoutes,
      isFetching: isFetchingDriverRoutes,
    } = useGetDriverRoutes({
      params: {
        id: queryParams?.driver_id,
      },
      onSuccess: (driverRoutesResponse: IDriverRouteResponse[]) => {
        const routesGrouped: IDriverRoute[] = groupDriverRoutes(
          driverRoutesResponse,
          true,
        );
        const routesSorted = sortDriverRoutes(routesGrouped);
        const routesUpdated = updateDriverRoutes(routesSorted);

        driverRoutes?.setDriverRoutes(routesUpdated);
        driverRoutes?.setUpdateDriverRoutes(routesUpdated);

        driverRoutes?.clearDriverRoutesMarkers();
        driverRoutes?.clearRoutes();

        if (routesUpdated.length) {
          driverRoutes?.addDriverRoutesMarkers(routesUpdated, {
            isOnManageRoutes,
            isOnlyOneRoute: driverRoutesResponse.length === 1,
          });
          driverRoutes?.setFnForceRender(setReRenderCount);
          driverRoutes?.drawRoutes({ isOnManageRoutes });
        }

        const driver = driverRoutes?.getDriverSelected();
        const driverRoutesMap = driverRoutes?.getDriverRoutesMap();

        if (isValidLatLngLiteral(driver)) {
          const driverPosition = { lat: driver.lat, lng: driver.lng };
          handleMapFitBounds(
            driverRoutesMap.map((r) => r.position).concat([driverPosition]),
            {
              padding: mapBoundsPadding.DRIVER_ROUTES,
              wait: 250,
            },
          );
        }

        setReRenderCount((v) => v + 1);
      },
    });

    const { mutate: offlineDriverMutation } = useOfflineDriverMutation({
      onSuccess: () => {
        handleCloseManageRoutes();
        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverList]);
      },
    });

    const {
      mutate: optimizedRoutesMutation,
      isLoading: isLoadingOptimizeRoute,
    } = useOptimizeRoutes({
      onSuccess: (optimizedRoutes: IDriverRouteResponse[]) => {
        const routesUpdatedOptimizedRoutes =
          updateOptimizedRoutesToDriverRoutes(optimizedRoutes, routes);
        const routesUpdated = updateDriverRoutes(routesUpdatedOptimizedRoutes);
        const routesSorted = sortDriverRoutes(routesUpdated, {
          isOnManageRoutes,
        });
        driverRoutes.setUpdateDriverRoutes(routesSorted);

        driverRoutes.clearDriverRoutesMarkers();
        driverRoutes.clearRoutes();

        driverRoutes?.addDriverRoutesMarkers(routesSorted, {
          isOnManageRoutes,
        });
        driverRoutes.drawRoutes({ isOnManageRoutes });
        setReRenderCount((v) => v + 1);
      },
    });

    const {
      mutate: updateDriverRoutesMutation,
      isLoading: isLoadingUpdateRoutes,
    } = useUpdateDriverRoutes({
      onSuccess: () => {
        const routesUpdated = driverRoutes.updateRoutes.map((r) => ({
          ...r,
          sort_id: r.update_sort_id || null,
          update_sort_id: null,
        }));

        driverRoutes.setUpdateDriverRoutes(routesUpdated);
        driverRoutes.setDriverRoutes(routesUpdated);

        driverRoutes.clearDriverRoutesMarkers();
        driverRoutes.clearRoutes();

        driverRoutes?.addDriverRoutesMarkers(routesUpdated, {
          isOnManageRoutes: false,
        });
        driverRoutes.drawRoutes({ isOnManageRoutes: false });

        offManageRoutes();
        setReRenderCount((v) => v + 1);

        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverRoutes]);
        showAlert(alertParams.successDark);
      },
    });

    useGetDriverDetail({
      params: {
        id: driverId,
      },
      onSuccess: (driver: Driver) => {
        if (driver?.id && driverRoutes) {
          driverRoutes.clearDriverInfoWindow();
          driverRoutes.addDriverInfoWindow(driver);
        }
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        forceRerender: () => setReRenderCount((v) => v + 1),
      }),
      [],
    );

    const isFirstLoadingManageRoutes = useFirstLoadingDetection([
      isFetchingDriverRoutes,
    ]);
    const driverData = driverRoutes?.getDriverSelected() || {};

    const isOnlyOneTaskManageRoutes = useMemo(
      () => driverRoutesResp.length === 1,
      [driverRoutesResp],
    );

    useEffect(() => {
      if (driverId) {
        offManageRoutes();
      }
    }, [driverId]);

    useEffect(() => {
      const isPendingDriverRoutes = driverRoutesResp?.some(
        (d) => !d.delivery_index && !d.pickup_index && !d.return_index,
      );

      if (
        isPendingDriverRoutes &&
        refetchGetDriverRoutesCountsRef.current >= 3
      ) {
        refetchGetDriverRoutesCountsRef.current += 1;
        refetchGetDriverRoutes();
      } else {
        refetchGetDriverRoutesCountsRef.current = 0;
      }
    }, [driverRoutesResp, refetchGetDriverRoutesCountsRef.current]);

    const handleSetRoute = useCallback(() => {
      const routesParams = convertDriverRoutesToRequestParams(routes);
      updateDriverRoutesMutation({
        driver_id: driverData.id,
        routes: routesParams,
      });
    }, [routes, driverData]);

    const handleOnManageRoute = useCallback(() => {
      refetchGetDriverRoutes();
      setViewControls((v: MarkerType[]) => [
        ...Array.from(new Set(v.concat([MarkerType.DropOff]))),
      ]);
      setIsOnManageRoutes(true);
      driverRoutes.clearDriverRoutesMarkers();
      driverRoutes.clearRoutes();

      driverRoutes?.addDriverRoutesMarkers(driverRoutes.updateRoutes, {
        isOnManageRoutes: true,
      });
      driverRoutes?.drawRoutes({ isOnManageRoutes: true });
    }, []);

    const handleReverseRoute = useCallback(() => {
      refetchGetDriverRoutes();
      offManageRoutes();
      driverRoutes.clearDriverRoutesMarkers();
      driverRoutes.clearRoutes();

      driverRoutes.setUpdateDriverRoutes(driverRoutes.routes);
      driverRoutes?.addDriverRoutesMarkers(driverRoutes.routes, {
        isOnManageRoutes: false,
      });

      driverRoutes?.drawRoutes({ isOnManageRoutes: false });
      setReRenderCount((v) => v + 1);
    }, []);

    const handleUpdateDriverRoutes = useCallback(
      (routeUpdated: IDriverRoute) => {
        const routesUpdated = updateDriverRoutes(
          driverRoutes.updateRoutes,
          routeUpdated,
        );

        const routesSorted = sortDriverRoutes(routesUpdated, {
          isOnManageRoutes: true,
        });

        driverRoutes?.setUpdateDriverRoutes(routesSorted);
        driverRoutes?.clearDriverRoutes();
        driverRoutes?.updateDriverRoutes(routesSorted);
        setReRenderCount((v) => v + 1);
      },
      [],
    );

    const getTaskIcon = (markerType: MarkerType) => {
      switch (markerType) {
        case MarkerType.Store:
          return (
            <>
              <img
                style={{ marginRight: 4, marginBottom: 1 }}
                src={PickUpIcon}
                alt='task-icon'
              />
              <span style={{ marginRight: 4, color: '#F7982A' }}>Pickup</span>
            </>
          );
        case MarkerType.DropOff:
          return (
            <>
              <img
                style={{ marginRight: 4, marginBottom: 1 }}
                src={DropOffIcon}
                alt='task-icon'
              />
              <span style={{ marginRight: 4, color: '#74D6BA' }}>Dropoff</span>
            </>
          );
        case MarkerType.Return:
          return (
            <>
              <img
                style={{ marginRight: 4, marginBottom: 1 }}
                src={ReturnIcon}
                alt='task-icon'
              />
              <span style={{ marginRight: 4, color: '#9B57D1' }}>Return</span>
            </>
          );
        default:
          return '';
      }
    };

    const headerComponent = useMemo(() => {
      const { display_name } = driverData;

      return (
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          sx={{ background: themes.bg.white, p: 2 }}
        >
          {isOnManageRoutes ? (
            <Stack direction='row' alignItems='center'>
              <IconButton
                sx={{
                  background: themes.bg.midPurple,
                  borderRadius: '5px',
                  width: 30,
                  height: 30,
                  mr: 1,
                }}
                onClick={handleReverseRoute}
              >
                <KeyboardArrowLeftIcon sx={{ color: themes.color.violet900 }} />
              </IconButton>
              <Typography variant='h4'>MANAGE ROUTE</Typography>
            </Stack>
          ) : (
            <ToolTipText
              text={display_name || '-'}
              maxLength={25}
              textNode={(v) => (
                <Typography
                  variant='h4'
                  sx={{
                    color: '#33363D',
                  }}
                >
                  {v}
                </Typography>
              )}
            />
          )}

          <Stack
            ml='auto !important'
            direction='row'
            spacing={0.5}
            maxHeight={themes.spacing(3)}
          >
            <ElementSelect
              paperProps={{
                sx: {
                  width: 120,
                  borderRadius: '10px',
                },
              }}
              iconButtonProps={{
                sx: {
                  width: '30px !important',
                },
              }}
              elementSelect={() => (
                <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
              )}
              onChange={(o) => {
                if (o.value === 'send-alert') {
                  setOpenSendAlert(true);
                }
                if (o.value === 'offline-driver') {
                  showAlert({
                    ...alertParams.warning,
                    description:
                      'You are about to offline this driver.\n Are you sure you want to continue?',
                    cancelText: 'Cancel',
                    onOk: () => {
                      offlineDriverMutation({ id: driverData.id });
                    },
                  });
                }
              }}
              options={[
                {
                  label: 'Send Alert',
                  value: 'send-alert',
                },
                {
                  label: 'Offline Driver',
                  value: 'offline-driver',
                },
              ].filter((o) =>
                !isShowOfflineDriver ? o.value !== 'offline-driver' : o,
              )}
            />
            <IconButton onClick={() => handleCloseManageRoutes()}>
              <Icon name='close' size={12} color={themes.bg.darkPurple} />
            </IconButton>
          </Stack>
        </Stack>
      );
    }, [driverData, isOnManageRoutes]);

    const listRoutesComponent = useMemo(
      () => (
        <Stack
          height='100%'
          px={1.5}
          py={1}
          spacing={1}
          className='customized-scrollbar'
          sx={{
            overflow: 'auto',
            background: themes.bg.lightPurple,
            boxSizing: 'border-box',
          }}
        >
          {isOnManageRoutes && (
            <Stack
              width='100%'
              sx={{
                background: '#E2E7F0',
                borderRadius: '5px',
                py: 0.4,
                color: '#33363D',
              }}
              textAlign='center'
            >
              <Typography p={0.2}>
                Click to put tasks in order or{' '}
                <span
                  role='button'
                  tabIndex={0}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() =>
                    optimizedRoutesMutation({ driver_id: driverData.id })
                  }
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      optimizedRoutesMutation({ driver_id: driverData.id });
                    }
                  }}
                >
                  Optimize Route
                </span>
              </Typography>
            </Stack>
          )}

          {routes.map((route, routeArrIdx) => {
            const isRouteDisabled = route.is_disabled && isOnManageRoutes;
            return (
              <Stack
                width='100%'
                key={route.id}
                direction='row'
                alignItems='center'
              >
                {isOnManageRoutes ? (
                  <Stack
                    onClick={() => {
                      if (isRouteDisabled) return;
                      const routesUpdated = routes.filter(
                        (r) => !!r.update_sort_id,
                      );
                      let routeUpdateSortId = 1;
                      if (routesUpdated.length) {
                        routeUpdateSortId = routesUpdated.length + 1;
                      }
                      if (
                        routesUpdated.length === 1 &&
                        routesUpdated[0].id === route.id
                      ) {
                        routeUpdateSortId = null;
                      }

                      handleUpdateDriverRoutes({
                        ...route,
                        update_sort_id: routeUpdateSortId,
                      });
                    }}
                    sx={{
                      borderRadius: '50%',
                      minWidth: 24,
                      height: 24,
                      cursor: 'pointer',
                      border: '1px solid #160E4D',
                      background: themes.bg.white,
                      color: themes.color.white,
                      fontSize: 14,
                      justifyContent: 'center',
                      alignItems: 'center',
                      ...(route.update_sort_id && {
                        background: themes.bg.darkPurple,
                      }),
                      ...(isRouteDisabled && {
                        cursor: 'default',
                        borderColor: themes.color.white,
                      }),
                    }}
                  >
                    {route.update_sort_id || ''}
                  </Stack>
                ) : (
                  <Typography textAlign='center' minWidth={30} fontWeight={500}>
                    {/* {isOnlyOneTaskManageRoutes ? '' : route.sort_id || ''} */}
                    {isOnlyOneTaskManageRoutes ? '' : routeArrIdx + 1 || ''}
                  </Typography>
                )}

                <Stack
                  width='100%'
                  p={1}
                  ml={0.5}
                  sx={{
                    background: themes.bg.white,
                    borderRadius: '5px',
                    position: 'relative',
                  }}
                >
                  {isRouteDisabled && (
                    <Box
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: themes.bg.white,
                        opacity: 0.7,
                        top: 0,
                        left: 0,
                        borderRadius: '5px',
                      }}
                    />
                  )}
                  <Stack
                    spacing={0.5}
                    direction='row'
                    justifyContent='space-between'
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: '#33363D',
                      }}
                    >
                      {getTaskIcon(route.marker_type)}
                      {route.task_name || ''}
                    </Typography>
                    <Typography
                      height={21}
                      color={route.is_alerted ? '#F75879' : '#8C95BA'}
                    >
                      {route.expected_delivery_before || ''}
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={0.5}
                    direction='row'
                    justifyContent='space-between'
                    flex={1}
                    mt={0.4}
                  >
                    <ToolTipText
                      text={`${route.address_1}, ${route.city}`}
                      maxLength={route.eta_time ? 18 : 40}
                      textNode={(v) => <Typography>{v}</Typography>}
                    />
                    <Typography
                      color={route.is_alerted ? '#F75879' : '#8C95BA'}
                    >
                      {!isOnManageRoutes && route.eta_time
                        ? `ETA ${moment()
                            .add(route.eta_time, 's')
                            .calendar(null, {
                              sameDay: 'h:mm a',
                              nextDay: 'h:mm a | [Tmrw]',
                              lastDay: 'h:mm a | [Ystd]',
                              nextWeek: 'hh:mm a | MM/DD',
                              lastWeek: 'hh:mm a | MM/DD',
                              sameElse: 'hh:mm a | MM/DD',
                            })}`
                        : '-'}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      ),
      [reReRenderCount, isOnManageRoutes, driverData.id],
    );

    const bottomButtonsComponent = useMemo(
      () => (
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='space-between'
          sx={{ background: themes.bg.white, p: 2 }}
        >
          {isOnManageRoutes ? (
            <>
              <Button
                buttonType='text'
                fullWidth
                rounder10
                onClick={handleReverseRoute}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                  alignItems: 'center',
                }}
              >
                <Icon
                  name='arrow-go-back'
                  size={16}
                  color={themes.bg.darkPurple}
                  style={{
                    marginBottom: 2,
                  }}
                />
                <Typography
                  sx={{
                    color: '#33363D',
                  }}
                >
                  Reverse Route
                </Typography>
              </Button>
              <Button
                buttonType='primary-dark'
                fullWidth
                rounder10
                disabled={routes.filter((r) => !!r.update_sort_id).length === 0}
                loading={isLoadingUpdateRoutes}
                onClick={handleSetRoute}
              >
                Update Route
              </Button>
            </>
          ) : (
            <Button
              buttonType='primary-dark'
              fullWidth
              rounder10
              onClick={handleOnManageRoute}
              disabled={
                isOnlyOneTaskManageRoutes || driverRoutesResp.length === 0
              }
            >
              Manage Route
            </Button>
          )}
        </Stack>
      ),
      [routes, isOnManageRoutes, isLoadingUpdateRoutes],
    );

    return (
      <StyledTaskDetail sx={{}}>
        {headerComponent}
        {isFirstLoadingManageRoutes ? (
          <FirstLoading />
        ) : (
          <>
            {listRoutesComponent}
            {bottomButtonsComponent}
          </>
        )}
        {openSendAlert && (
          <DialogSendAlert
            open={openSendAlert}
            onClose={() => setOpenSendAlert(false)}
          />
        )}
        {isLoadingOptimizeRoute && <OptimizeRouteLoading />}
      </StyledTaskDetail>
    );
  },
);
