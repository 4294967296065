import { createStyles, Divider, Grid, Stack, Typography } from '@mui/material';
import Button from '~/components/common/Button';
import { useLocation } from 'react-router-dom';
import {
  IStore,
  OrderLimitType,
  StorePlatform,
  DeliveryHourType,
  ORDER_LIMIT_OPTIONS,
  TimeSlotsType,
} from '~/models/stores';
import { fromQueryString } from '~/utils/queryString';
import { Field } from '~/components/common/Formik';
import { Form, Formik, getIn } from 'formik';
import { AllOrderLimitSchema } from '~/utils/schema/stores';
import { useRef, useState } from 'react';
import { useUpdateConnectionMutation } from '~/services/api/userManagement';
import EditIcon from '~/assets/images/icons/edit.svg';
import { weekdays } from '~/constants/stores';
import themes, { Theme } from '~/themes';
import { withStyles, WithStyles } from '@mui/styles';
import { TextInput } from '~/components/common/TextInput';
import { isNaN } from 'lodash';
import { DialogIndividualTimeSlots } from './DialogIndividualTimeSlots';

const style = (theme: Theme) =>
  createStyles({
    switchLabel: {
      marginLeft: theme.spacing(1),
    },
  });

type Props = {
  data: IStore;
  isFetching?: boolean;
};

const OrderLimit = ({
  data,
  isFetching,
  classes,
}: Props & WithStyles<typeof style>) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const platform = search.platform as StorePlatform;
  const orderLimitAllInputRef = useRef(null);
  const [openSetLimitDialog, setOpenSetLimitDialog] = useState(false);

  const { mutate: updateConnectionMutation, isLoading } =
    useUpdateConnectionMutation({});

  const getLimitRange = (time_slot) => {
    let min = Infinity;
    let max = -Infinity;

    time_slot.forEach((slot) => {
      if (slot.limit !== null) {
        min = Math.min(min, slot.limit);
        max = Math.max(max, slot.limit);
      }
    });
    if (min === max) return `${min}`;
    return `${min}-${max}`;
  };

  const convertedDeliveryHours = (deliveryHours) =>
    weekdays.map(({ value: wd }) => {
      const hours = deliveryHours[wd];
      const {
        time_slot,
        time_slot_type = TimeSlotsType.LimitAllSlot,
        limit_all_zone,
        limit_zones,
      } = hours;
      const timeSlots = time_slot?.flat();
      let orderLimit = '';
      if (time_slot_type === TimeSlotsType.LimitAllSlot) {
        if (limit_all_zone) orderLimit = hours.limit ? hours.limit : 'N/A';
        else {
          orderLimit = getLimitRange(limit_zones);
        }
      }
      if (time_slot_type === TimeSlotsType.LimitIndividualSlot) {
        orderLimit = getLimitRange(timeSlots);
      }
      return {
        ...hours,
        weekdayValue: wd,
        weekDayLabel: wd.charAt(0).toUpperCase() + wd.slice(1),
        type: time_slot_type,
        orderLimit,
      };
    });

  const onSubmit = (values) => {
    if (values.limit_type === OrderLimitType.LimitIndividualSlot) {
      values.limit_all_slot = null;
    }

    const params = {
      id: data.id,
      enable_delivery: values.enable_delivery,
      enable_limit: values.enable_limit,
      ...(values.enable_delivery && {
        delivery: values,
      }),
      ...(values?.limit_type && {
        limit_type: values.limit_type,
      }),
      limit_all_slot: values.limit_all_slot
        ? parseInt(values.limit_all_slot, 10)
        : null,
      delivery_hour_type: values.delivery_hour_type,
    };

    updateConnectionMutation(params);
  };

  const renderEachTimeSlot = (values) => {
    const getZoneLabel = (hours) => {
      const { limit_all_zone, limit_zones } = hours;
      if (limit_all_zone) return 'All zones';
      return `${limit_zones?.length} individual zone${
        limit_zones?.length > 1 ? 's' : ''
      }`;
    };

    const deliveryHours = convertedDeliveryHours(values.delivery_hours);
    return (
      <Stack flexDirection='column' mt={2} p={0.5} gap={1.6}>
        <Grid
          container
          spacing={1}
          sx={{
            color: '#8C95BA',
          }}
        >
          <Grid item xs={3}>
            <Typography variant='caption'>Day of the week</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='caption'>Time slots</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='caption'>Delivery zones</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='caption'>Order limit</Typography>
          </Grid>
        </Grid>
        {deliveryHours?.map((item) => (
          <Grid container spacing={1} key={item.weekdayValue}>
            <Grid item xs={3}>
              <Typography variant='body1'>{item.weekDayLabel}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='body1'>
                {item.time_slot_type === TimeSlotsType.LimitAllSlot
                  ? 'All time slots'
                  : `${item.time_slot?.length} individual slots`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='body1'>{getZoneLabel(item)}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='body1'>{item.orderLimit}</Typography>
            </Grid>
          </Grid>
        ))}
        <Stack>
          <Button
            noRounder
            buttonType='default'
            sx={{
              mt: 1,
              gap: '4px',
              width: 'fit-content',
            }}
            onClick={() => {
              setOpenSetLimitDialog(true);
            }}
          >
            <img
              src={EditIcon}
              alt=''
              style={{
                width: 16,
                height: 16,
              }}
            />
            Edit order limit
          </Button>
        </Stack>
      </Stack>
    );
  };

  if (platform === StorePlatform.Breadstack) {
    return (
      <Stack spacing={2}>
        <Typography
          sx={{
            borderRadius: '5px',
            background: themes.bg.lightPurple,
            padding: 1,
          }}
        >
          Set the limit to number of order that can be place within a delivery
          time slot.
        </Typography>
        <Button
          noRounder
          buttonType='default'
          sx={{ width: 'fit-content' }}
          onClick={() => window.open(data.breadstack_url, '_blank')}
        >
          Manage in Breadstack
        </Button>
      </Stack>
    );
  }

  return (
    <Formik
      initialValues={{
        delivery_window: 15,
        next_available_time: 30,
        ...data.delivery,
        enable_delivery: data.enable_delivery || false,
        enable_limit: data.enable_limit || false,
        limit_all_slot: data?.limit_all_slot || '',
        delivery_hour_type:
          data?.delivery?.delivery_hour_type || DeliveryHourType.Different,
        delivery_hours: data.delivery?.delivery_hours,
        special_hours: data.delivery?.special_hours || [],
        limit_type: data?.limit_type || OrderLimitType.LimitAllSlot,
      }}
      validationSchema={AllOrderLimitSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, dirty, touched, errors }) => (
        <Form id='order_limit'>
          <Stack flexDirection='column' gap={1}>
            <Typography color={themes.color.black} variant='h5'>
              Enable Order Limit
            </Typography>
            <Stack flexDirection='row' alignItems='center' mt={1}>
              <Field.SwitchButton
                noText
                name='enable_limit'
                formClasses={{
                  label: classes.switchLabel,
                }}
              />
              Set the limit to number of order that can be place within a
              delivery time slot.
            </Stack>
          </Stack>
          {values?.enable_limit && (
            <>
              <Divider
                sx={{
                  my: 2,
                }}
              />
              <Typography color={themes.color.black} variant='h5'>
                Set Order Limit
              </Typography>
              <Stack mt={1.6}>
                <Field.Select
                  fullWidth
                  name='limit_type'
                  options={ORDER_LIMIT_OPTIONS.map((o) => ({
                    value: o.value,
                    label: `${o.label}`,
                  }))}
                  formControlProps={{
                    sx: { width: '100%' },
                  }}
                  onChange={() => {}}
                  legend='How would you like to set order limit?'
                />
              </Stack>
              {values.limit_type === OrderLimitType.LimitAllSlot && (
                <Stack mt={1.6}>
                  <TextInput
                    type='number'
                    inputRef={orderLimitAllInputRef}
                    legend='Order limit'
                    name='limit_all_slot'
                    inputProps={{
                      min: 1,
                      step: 'any',
                      autoFocus: true,
                    }}
                    sx={{ width: '100%', mt: 2 }}
                    value={values.limit_all_slot || ''}
                    onChange={(event) => {
                      const value = parseInt(event.target.value, 10);
                      setFieldValue(
                        'limit_all_slot',
                        isNaN(value) ? '' : value,
                      );
                    }}
                    error={
                      !!getIn(touched, 'limit_all_slot') &&
                      !!getIn(errors, 'limit_all_slot')
                    }
                    helperText={
                      getIn(touched, 'limit_all_slot') &&
                      getIn(errors, 'limit_all_slot')
                    }
                  />
                </Stack>
              )}
              {values.limit_type === OrderLimitType.LimitIndividualSlot &&
                renderEachTimeSlot(values)}
            </>
          )}
          <Button
            disabled={!dirty}
            className='save-change-btn'
            fullWidth
            loading={isLoading || isFetching}
            type='submit'
            form='order_limit'
            id='order_limit'
            // onClick={submitForm}
          >
            Save Changes
          </Button>
          {openSetLimitDialog && (
            <DialogIndividualTimeSlots
              open={openSetLimitDialog}
              stores={{ ...data, ...values }}
              onClose={() => setOpenSetLimitDialog(false)}
              onSaveDataSetLimit={(val) => {
                setFieldValue('delivery_hours', val);
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default withStyles(style)(OrderLimit);
